/*
 * @Author: 张超越 
 * @Date: 2024-03-19 10:54:32 
 * @Last Modified by: 张超越
 * @Last Modified time: 2024-05-06 10:37:38
 */

<template>
  <div ref="BasicInformation" :class="['BasicInformation', fromModule === 'createClue' ? 'BasicInformation_n' : '']">
    <div class="body">
      <div v-if="title" class="title-box">
        <div class="title">{{ title }}</div>
      </div>

      <van-cell-group class="ul">
        <div v-for="(item, index) of getList" :key="index">
          <!-- hr，分割线，二级分组名 -->
          <div v-if="item.type === 'hr'" class="hr">{{ item.name }}</div>

          <!-- 选择框 -->
          <van-cell v-else-if="item.type === 'select'" :is-link="item.checkType != 1" :title="item.keyName" :value="getDValue(item.value, item.keyCode)" @click="handleCellClick(item, index)">
            <!-- 判断是否行内操作 -->
            <div v-if="item.checkType == 1" class="tags">
              <div v-for="(tag, tIndex) of getDItems(item.keyCode)" :key="tIndex" :class="['tag', item.value == tag.valueCode ? 'active' : '']" @click="handleActionSheetSelect(tag, index)">{{ tag.name }}</div>
            </div>
          </van-cell>

          <!-- 地址选择 -->
          <van-cell v-else-if="item.type === 'area'" is-link :title="item.keyName" :value="getAddressValue(item)" @click="handleAreaClick(item, index)">
          </van-cell>

          <!-- 地址（城市）选择 -->
          <van-cell v-else-if="item.type === 'city'" is-link :title="item.keyName" :value="getAddressValue(item)" @click="handleAreaClick(item, index, 2)">
          </van-cell>

          <!-- 车型选择 -->
          <van-cell v-else-if="item.type === 'model'" is-link :title="item.keyName" :value="getCarValue(item.value, item.keyCode)" @click="handleModelCellClick(item, index)">
          </van-cell>

          <!-- 多选框 -->
          <van-cell v-else-if="item.type === 'multi_select'" is-link :title="item.keyName" :value="getMDValue(item.value, item.keyCode)" @click="handleMSCellClick(item, index)">
          </van-cell>

          <!-- 级联多选框 -->
          <template v-else-if="item.type === 'multi_cascader'">
            <template v-if="item.keyCode === 'concern'">
              <van-cell v-if="!showConcern" is-link :title="item.keyName" :value="getMCValue(item.value, item.keyCode)" @click="handleMCCellClick(item, index)">
              </van-cell>
            </template>
            <van-cell v-else is-link :title="item.keyName" :value="getMCValue(item.value, item.keyCode)" @click="handleMCCellClick(item, index)">
            </van-cell>
          </template>

          <!-- 特殊，竞品 -->
          <van-cell v-else-if="item.type === 'competitor_model'" is-link :title="item.keyName" :value="getCMValue(item.value, item.keyCode)" @click="handleCMCellClick(item, index)">
          </van-cell>

          <!-- 渠道来源 -->
          <van-cell v-else-if="item.keyCode === 'channelInfo'" :is-link="changeClueChannel ? true : false" :title="item.keyName" :value="getChannelValue(item.value)" @click="onClueSourceSelect(item)">
          </van-cell>

          <!-- 输入框 -->
          <template v-else-if="item.type === 'text'">
            <div v-if="item.keyCode === 'carOrderMaterial'" style="background-color: #fff;color: #0d171a;padding: 10px 16px;box-sizing: border-box;">
              <van-row v-if="!showConcern && item.value">
                <van-col span="8">{{$t('选配信息')}}</van-col>
                <van-col span="16" style="text-align: right;">
                  <div>车系车型：{{ item.value?.seriesName }} {{ item.value?.modelName }}</div>
                  <div>外饰颜色：{{ item.value?.colourName }}</div>
                  <div>内饰颜色：{{ item.value?.interiorName }}</div> 
                  <!-- <div v-if="clueDetail.orders?.[0].carOrderMaterial?.carOptionsList">选装：
                    <div v-for="(item, index) in clueDetail.orders?.[0].carOrderMaterial?.carOptionsList" :key="index">{{index + 1}}.{{ item.optionsName }}</div>
                  </div> -->
                </van-col>
              </van-row>
            </div>
            <template v-else-if="item.keyCode === 'concernRemark'">
              <van-field
                v-if="!showConcern"
                v-model="item.value"
                input-align="right"
                rows="3"
                autosize
                type="textarea"
                maxlength="500"
                show-word-limit
                :name="item.keyCode"
                :label="item.keyName"
                :placeholder="$t('请输入')"
                :error-message="item._errorTips"
                :error-message-align="'right'"
                :disabled="getDisabledForInput(item) || item.dataType === 2"
                @input="getInputErrorTips(item)"
              />
            </template>
            <template v-else-if="item.keyCode === 'drivePersonCount'">
              <van-field
                v-if="!showConcern"
                v-model="item.value"
                input-align="right"
                :name="item.keyCode"
                :label="item.keyName"
                :placeholder="$t('请输入')"
                :type="getInputType(item)"
                :error-message="item._errorTips"
                :error-message-align="'right'"
                :disabled="getDisabledForInput(item) || item.dataType === 2"
                @input="getInputErrorTips(item)"
              >
              </van-field>
              </template>
            <van-field
              v-else
              v-model="item.value"
              input-align="right"
              :name="item.keyCode"
              :label="item.keyName"
              :placeholder="$t('请输入')"
              :type="getInputType(item)"
              :error-message="item._errorTips"
              :error-message-align="'right'"
              :disabled="getDisabledForInput(item) || item.dataType === 2"
              @input="getInputErrorTips(item)"
            >
            </van-field>
          </template>

          <div v-else-if="item.type === 'list'" class="tags2">
            <!-- 标签 -->
            <div v-for="(tag, TIndex) of item.value" :key="TIndex" class="tag">
              {{ getHobbyDName(tag) }}
            </div>

            <div class="tag icon" @click="handleListSelectShow(item)">+</div>
          </div>
        </div>
      </van-cell-group>
    </div>

    <div v-if="showBtn" class="footer-box">
      <div class="button" @click="handleClose">{{ $t('返回') }}</div>
      <div class="button submit" @click="handleOK">{{ $t('确定') }}</div>
    </div>

    <!-- 弹出框 -->
    <van-action-sheet
      v-model="show"
      :actions="actions"
      :title="actionSheetTitle"
      :cancel-text="$t('取消')"
      close-on-click-action
      @select="handleActionSheetSelect"
    />
   
    <!-- 弹出框，多选 -->
    <van-popup v-model="multiSelect" style="width:100%; height:100%">
      <SelectMulti 
        v-if="multiSelect" 
        :title="titleForMSelect" 
        :list="listForMSelect"
        :details="detailsForMSelect?.value || []"
        @close="multiSelect = false"
        @ok="handleMSelect"
        >
      </SelectMulti>
    </van-popup>

    <!-- 地区选择 -->
    <van-popup v-model="showArea" position="bottom">
      <van-area
        :area-list="areaList"
        :columns-num="areaNum"
        @confirm="onConfirm"
        @cancel="showArea = false"
      />
    </van-popup>

    <!-- 多级选择框 -->
    <form-select-wrap-cascader
      :columns="cmList"
      :showPicker="showCMPicker"
      :title="i18n.t('请选择')"
      :loading="cmLoading"
      @confirm="onCMConfirm"
      @cancel="cmCancel"
    >
    </form-select-wrap-cascader>

    <!-- 兴趣爱好 -->
    <van-popup v-model="hobby" style="width:100%; height:100%">
      <SelectTags v-if="hobby" :title="listSelectTitle" :details="listDetailsItems" @close="hobby = false" @ok="handleHobbiesOK"></SelectTags>
    </van-popup>

    <!-- 竞品 -->
    <van-popup v-model="competing" style="width:100%; height:100%">
      <SelectCompeting v-if="competing" :list="cmList" :title="listSelectCompetTitle" :details="listCompetingDetailsItems" @close="competing = false" @ok="handleCompetingOK"></SelectCompeting>
    </van-popup>

    <!-- 公共级联选择器多选 -->
    <van-popup v-model="multipleCascadering" style="width:100%; height:100%">
      <Cascader v-if="multipleCascadering" :list="listMultipleCascaderList" :title="listMultipleCascaderTitle" :details="listMultipleCascaderDetailsItems" @close="multipleCascadering = false" @ok="handleMultipleCascaderOK"></Cascader>
    </van-popup>

  </div>
</template>

<script>
// APIs
import baseDataServices from '@/services/baseDataServices'
import clueServices from '@/services/clueServices'

// Tools
import loading from '@/utils/loading'
import { isArray, cloneDeep, isEmpty, last, isEqual } from 'lodash'
import { areaList } from '@vant/area-data'
import { mapGetters } from 'vuex'
import selectCar from '@/components/select-car'
import { i18n } from '@/main'
import dayjs from 'dayjs'

// Components
import SelectMulti from './SelectMulti.vue'
import SelectTags from './SelectTags.vue'
import formSelectWrapCascader from '@/components/select-car/form-select-wrap-cascader'
import SelectCompeting from './SelectCompeting.vue'
import Cascader from './Cascader.vue'
import selectClueSource from '@/components/select-clue-source'

export default {
  name: 'BasicInformation',
  components: {
    SelectMulti,
    SelectTags,
    SelectCompeting,
    Cascader,
    formSelectWrapCascader,
  },
  props: {
    dictionaries: {
      type: Array,
      default: () => []
    },
    keyCode: {
      type: String,
      default: '',
    },
    details: {
      type: Array,
      default: () => []
    },
    leadId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    showBtn: {
      type: Boolean,
      default: () => true
    },
    fromModule: { // 从哪个页面引入
      type: String,
      default: ''
    },
    clueInfo: {
      type: Object,
      default: () => {}
    },
    showConcern: { // 跟进时，客户顾虑相关字段是否展示
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      // 公共级联选择器相关
      listMultipleCascaderTitle: '',
      listMultipleCascaderDetailsItems: [],
      listMultipleCascaderList: [],
      multipleCascadering: false,
      listMultipleCascaderItemActiveKeyCode: '',
      
      listSelectCompetTitle: '',
      listSelectTitle: '',
      listDetailsItems: [],
      listCompetingDetailsItems: [],
      competing: false,
      hobby: false,
      showArea: false,
      show: false,
      actionSheetTitle: '请选择',
      actions: [],
      list: [],
      // areaList,
      areaList,
      titleForMSelect: '请选择',
      multiSelect: false,
      listForMSelect: [],
      cmList: [],
      detailsForMSelect: {},
      cmLoading: false,
      showCMPicker: false,
      areaNum: 3,
      i18n,
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
    getLangIsZH() {
      const lang = i18n.locale
      return lang.includes('zh')
    },
    getList() {
      return this.list.filter(i => {
        // if (i.type === 'hr') return false
        if (i.hidden === true) {
          return false
        } else if (i.keyCode == 'companyName') {
          // 公司名称是否显示，取决于客户类型的值
          const userTypeItem = this.list.find(i => i.keyCode === 'userType')
          if (!userTypeItem) return true

          if (userTypeItem.value == 2) return true

          return false
        } else if (i.keyCode === 'partnerTry') {
          // 共同决策人是否试驾 是否显示，取决于 共同决策人 的值
          const userTypeItem = this.list.find(i => i.keyCode === 'coMakers')
          if (!userTypeItem) return true

          if (!['103005', '103006', ''].includes(String(userTypeItem.value))) return true

          i.value = '100202'
          return false
        } else {
          return true
        }
      })
    },
    // 半小时内且是门店员工手动新建的线索 允许修改渠道来源
    changeClueChannel() {
      if (!this.clueInfo) return false
      const { createTime, source } = this.clueInfo
      return dayjs().isBefore(dayjs(createTime).add('30','minutes')) && source === '1008003'
    }
  },
  created() {
    this.init()
  },
  mounted() {
  },
  methods: {
    async init() {
      this.list = this.details.map(i => {
        return {
          ...i,
          value: i.value ?? ''
        }
      })

      console.info('init list', this.list)
      // this.ids = this.details.map(i => {
      //   return { valueCode: i }
      // })

      // 加载竞品列表
      const cmList = await baseDataServices.getCompetitorModel()

      this.cmList = cmList.map(i => {
        const { brandName: text, brandNameEn, models = [], brandCode: value } = i
        const children = models.map(i => {
          const { modelName: text, modelCode: value, modelNameEn } = i
          return {
            ...i,
            text,
            name: text,
            nameEn: modelNameEn,
            value
          }
        })
        return {
          ...i,
          text,
          name: text,
          nameEn: brandNameEn,
          value,
          children
        }
      })

      console.info('cmList', this.cmList)

      // this.onBackEvent()
    },
    getHobbyDName(tagCode) {
      const tags = this.dictionaries.filter(i => i.keyCode === 'hobby') || []
      const item = tags.find(i => i.valueCode == tagCode)

      return item?.valueName || tagCode
    },
    getAddressValue(item) {
      console.info('getAddressValue', item)

      if (!isArray(item.value)) {
        return item.value
      }

      let arr = (item.value || []).map(i => i.name)

      // 如果是英文，则翻转
      if (!this.getLangIsZH) {
        arr = arr.reverse()
      }

      const str = arr.join(' ')
      return str
    },
    getDItems(key) {
      return this.dictionaries
        .filter(i => i.keyCode === key)
        .map(i => {
          const { valueName: name } = i
          return {
            ...i,
            name
          }
        })
    },
    getCarValue(obj = {}, keyCode) {
      console.info('getCarValue', obj, keyCode)
      if (typeof(obj) !== 'object') return obj
      const { seriesName = '', modelName = '' } = obj
      return `${seriesName} ${modelName}`
    },
    getMCValue(valueCode, keyCode) {
      console.info('getMCValue', valueCode, keyCode)
      if (!valueCode || !isArray(valueCode)) return ''

      let strs = valueCode.map(item => {
        const { name, nameEn, value, children } = item
        let str = this.getLangIsZH ? name : nameEn

        let cStr = []
        if (children && isArray(children) && children.length && children.length > 0) {
          str += this.$t('：')
          children.forEach(j => {
            const { name, nameEn, value } = j
            cStr.push(this.getLangIsZH ? name : nameEn)
          })

          if (cStr && cStr.length) {
            str += ' ' + cStr.join(this.$t('，'))
          }
        }

        return str
      })

      return strs.join(this.$t('；'))
    },
    getCMValue(valueCode, keyCode) {
      console.info('getCMValue', valueCode, keyCode)
      if (!valueCode || !isArray(valueCode)) return ''

      let strs = valueCode.map(item => {
        const { name, nameEn, code, models } = item
        let str = this.getLangIsZH ? name : nameEn
        str += this.$t('：')
        
        let modelsStr = []
        if (models && isArray(models) && models.length) {
          models.forEach(j => {
            const { name, nameEn, code } = j
            modelsStr.push(this.getLangIsZH ? name : nameEn)
          })

          if (modelsStr && modelsStr.length) {
            str += ' ' + modelsStr.join(this.$t('，'))
          }
        }

        return str
      })

      return strs.join(this.$t('；'))
    },
    getMDValue(valueCode, keyCode) {
      console.info('getMDValue', valueCode, keyCode)
      if (!valueCode || !valueCode.length) return ''

      const items = this.getDItems(keyCode)
      console.info('items', items)
      valueCode = isArray(valueCode) ? valueCode : valueCode.split(';')
      const item = valueCode
        .map(i => items.find(j => j.valueCode == i) || { name: i })
        .map(i => i?.name)

      console.info('getMDValue item', item)

      return item.join(this.$t('、'))
    },
    getDValue(valueCode, keyCode) {
      console.info('getDValue', valueCode, keyCode)
      if (!valueCode) return ''

      const items = this.getDItems(keyCode)
      console.info('items', items)

      const item = items.find(i => i.valueCode == valueCode)

      console.info('item', item)

      return item?.name || valueCode
    },
    getChannelValue(value) {
      const { channelOne, channelTwo, channelThree, channelFour, channelFive } = value
      let channelArr = []
      channelOne && channelArr.push(channelOne)
      channelTwo && channelArr.push(channelTwo)
      channelThree && channelArr.push(channelThree)
      channelFour && channelArr.push(channelFour)
      channelFive && channelArr.push(channelFive)
      return channelArr.join('/')
    },
    getInputType(item) {
      if (!item) return 'text'

      if (item.keyCode === 'userMobile') return 'tel'

      return 'text'
    },
    async getInputErrorTips(item) {
      if (!item) return item._errorTips = ''

      if (item.keyCode === 'userMobile') {
        if (!item.value) return item._errorTips = ''

        // 验证手机号是否正确
        if (/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(item.value)) {
          item._errorTips = ''

          loading.showLoading(this.$t('线索验证中'))
          const params = {
            userMobile: item.value,
            dealerId: this.userInfo.dealers[0]?.id,
          }
          const record = await clueServices.clueExist(params).finally(() => loading.hideLoading())
          this.handleLink(record, item)

          return
        }

        return item._errorTips = this.$t('请输入11位的手机号')
      }

      return item._errorTips = ''
    },
    // 去跟进
    goFollow(id) {
      this.$router.push({
        path: '/clue-follow',
        query: {
          id,
        }
      })
    },
    // 校验结果处理
    handleLink(data, itemObj) {
      if (!data.id) return false
      // 有线索 但无跟进人
      if (!data.followUserId) {
        this.$dialog.confirm({
          title: this.$t('提示'),
          message: this.$t('该线索还未分配跟进人，是否领取 ？'),
          confirmButtonColor: '#B9921A',
          confirmButtonText: this.$t('领取'),
        })
          .then(async () => {
            const result = await clueServices.receiveClue({ id: data.id })
            if (result) {
              this.$toast(this.$t('领取成功'))
              setTimeout(() => {
                this.goFollow(data.id)
              }, 1000)
            }
          })
          .finally(data => {
            itemObj.value = ''
          })
      }
      // 有线索 跟进人是自己
      if (data.followUserId && data.followUserId === this.userInfo.id) {
        this.$dialog.confirm({
          title: this.$t('提示'),
          message: this.$t('您正在跟进该客户，无需重复创建，是否去填写跟进记录？'),
          confirmButtonColor: '#B9921A',
          confirmButtonText: this.$t('去填写'),
        })
          .then(() => {
            this.goFollow(data.id)
          })
          .finally(data => {
            itemObj.value = ''
          })
      }
      // 有线索 跟进人是他人
      if (data.followUserId && data.followUserId !== this.userInfo.id) {
        this.$dialog.confirm({
          title: this.$t('提示'),
          message: `【${data.followUserName}${this.$t('】正在跟进该客户，不允许重复创建，您是否去填写跟进记录？')}`,
          confirmButtonColor: '#B9921A',
          confirmButtonText: this.$t('去填写'),
        })
          .then(() => {
          // this.goFollow(data.id)
            this.$router.push({
              path: '/clue-remark',
              query: {
                id: data.id,
              }
            })
          })
          .finally(data => {
            itemObj.value = ''
          })
      }
    },
    setAreaMockData() {
      // 中文环境不变，英文固定
      if (this.getLangIsZH) return false

      this.areaList = {
        province_list: {
          340100: 'Anhui province',
        },
        city_list: {
          340110: 'Hefei city',
        },
        county_list: {
          340111: 'Baohe district',
          // ....
        }
      }
    },
    getDisabledForInput(item) {
      // 手机号有内容就禁止编辑该项
      if (!item || item.keyCode !== 'userMobile') return false

      const oldItem = this.details.find(i => i.keyCode === 'userMobile')
      return Boolean(oldItem.value)
    },
    onCityConfirm(values) {
      console.info('values', values)
      const areaItem = this.list.find(i => i.keyCode === 'carCity')
      if (!areaItem) return this.showArea = false
      areaItem.value = values
      
      this.showArea = false
    },
    onConfirm(values) {
      console.info('values', values)

      // 区别城市选择
      if (this.areaNum == 2) {
        this.onCityConfirm(values)
        return false
      }

      const areaItem = this.list.find(i => i.keyCode === 'address')
      areaItem.value = values
      
      this.showArea = false
    },
    handleListSelectShow(item) {
      console.info('handleListSelectShow', item)
      this.listSelectTitle = item.name
      this.listDetailsItems = item.value || []
      this.hobby = true
    },
    handleHobbiesOK(values) {
      console.info('handleHobbiesOK', values)
      const item = this.list.find(i => i.keyCode === 'hobby' && i.type === 'list')
      if (!item) return false

      const allowedsCodes = this.getDItems('hobby').map(i => i.valueCode)

      item.value = values.filter(i => allowedsCodes.includes(i))
      console.info('handleHobbiesOK item', item)
      this.hobby = false
    },
    handleCompetingOK(values) {
      console.info('handleCompetingOK', values)
      const item = this.list.find(i => i.type === 'competitor_model')
      if (!item) return false

      item.value = values
      console.info('handleCompetingOK item', item)
      this.competing = false
    },
    handleMultipleCascaderOK(values) {
      console.info('handleMultipleCascaderOK', values)
      const item = this.list.find(i => i.keyCode === this.listMultipleCascaderItemActiveKeyCode)
      if (!item) return false

      item.value = values
      console.info('handleCompetingOK item', item)
      this.multipleCascadering = false
    },
    handleAreaClick(item, index, areaNum = 3) {
      console.info('handleAreaClick', item, index)
      this.areaNum = areaNum

      this.setAreaMockData()
      this.showArea = true
    },
    handleActionSheetSelect(item, index) {
      console.info('handleActionSheetSelect', item, index)

      const listItem = this.list.find(i => i.keyCode === item.keyCode)
      if (!listItem) return false

      listItem.value = item.valueCode
    },
    handleCellClick(item, index) {
      console.info('handleCellClick', item, index)
      if (item.checkType == 1 || item.dataType === 2) return false

      this.actionSheetTitle = item.keyName
      this.actions = this.getDItems(item.keyCode)
      console.info('this.actions', this.actions)
      this.show = true
    },
    handleModelCellClick(item, index) {
      console.info('handleModelCellClick', item, index)
      selectCar({})
        .then(carInfo => {
          console.info('carInfo', carInfo)
          if (typeof carInfo.seriesCode === 'undefined') {
            this.$toast('请选择正确的车系')
            return
          }

          item.value = carInfo
        })
        .catch((err) => console.error(err))
    },
    handleCMCellClick(item, index) {
      // 竞品车型专属
      // this.showCMPicker = true // 单选
      this.listSelectCompetTitle = item.name
      this.listCompetingDetailsItems = item.value

      // 多选组件
      this.competing = true
    },
    async handleMCCellClick(item, index) {
      console.info('handleMCCellClick', item, index)
      // 公共级联多选
      // this.showCMPicker = true // 单选
      this.listMultipleCascaderTitle = item.name
      this.listMultipleCascaderDetailsItems = item.value
      this.listMultipleCascaderItemActiveKeyCode = item.keyCode
      let listMultipleCascaderList = this.getDItems(item.keyCode)

      this.listMultipleCascaderList = listMultipleCascaderList.map(i => {
        // 如果有大于2级的， 后期修改该方法扩展
        let { valueName: name, valueNameEn: nameEn, valueCode: value, children } = i
        if (children && isArray(children) && children.length) {
          children = children.map(j => {
            let { valueName: name, valueNameEn: nameEn, valueCode: value } = j
            return {
              name, nameEn, value, text: name
            }
          })
        }
        return {
          name, nameEn, value, children, text: name
        }
      })

      if (item.keyCode === 'concern') {
        listMultipleCascaderList = await baseDataServices.clueConcernsTree({ topLevel: 1 })
        this.listMultipleCascaderList = listMultipleCascaderList.map(i => {
          const { name, id } = i
          const children = i.children.map(i => {
            const { name, id } = i
            return {
              ...i,
              text: name,
              value: id
            }
          })
          return {
            ...i,
            text: name,
            value: id,
            children
          }
        })
      } 
      // 多选组件
      this.multipleCascadering = true
    },
    cmCancel() {
      this.showCMPicker = false
    },
    onCMConfirm(value) {
      console.info('onCMConfirm', value)
      if (value && value.length > 0) {
        if (value.length !== 2) {
          this.$toast('请完成全部选择')
          return false
        }
      }

      const cmItem = this.list.find(i => i.keyCode === 'competitive')
      console.info('onCMConfirm', cmItem)
      if (!cmItem) return this.showCMPicker = false

      // 清理value
      const { brandCode, brandName, brandNameEn, modelCode, modelName, modelNameEn, text } = last(value)
      const obj = { brandCode, brandName, brandNameEn, modelCode, modelName, modelNameEn, text }

      cmItem.value = obj
      console.info('competitive updated', cmItem.value)

      this.showCMPicker = false
    },
    handleMSCellClick(item, index) {
      console.info('handleMSCellClick', item, index)
      this.titleForMSelect = item.keyName
      this.listForMSelect = this.getDItems(item.keyCode)
      this.detailsForMSelect = item
      this.multiSelect = true
    },
    handleMSelect(ids = []) {
      console.info('handleMSelect', ids)
      this.detailsForMSelect.value = ids.join(';')
      this.multiSelect = false
    },
    onBackEvent() {
      setTimeout(() => {
        try {
          wx.onHistoryBack(() => {
            const bools = [this.show, this.showArea]

            if (bools.some(Boolean)) {
              bools.forEach(i => i = false)
              return false
            }
            return true
          })
        } catch (error) {
          console.error(error)
        }
      }, 1000)
    },
    handleClose() {
      this.$emit('close')
    },
    // 导出数据 用于线索创建
    EXPORTDATA() {
      let obj = {}
      for (let i of this.list) {
        console.info('i', i)

        // 验证信息
        if (i._errorTips) {
          this.$toast(i._errorTips)
          return false
        }

        const { keyCode, value } = i

        // 识别value是否和入参一样，不一样才更改
        const propsItem = this.details.find(i => i.keyCode === keyCode)
        if (!propsItem) continue

        // if (JSON.stringify(propsItem.value) === JSON.stringify(value)) continue
        if (isEqual(propsItem.value, value)) continue
        if (value == '') continue
        obj[keyCode] = value
      }
      // 如果obj为空，则直接关闭
      if (isEmpty(obj)) {
        this.$emit('ok', this.ids)
        return false
      }
      return obj
    },
    async handleOK() {
      loading.showLoading()

      let obj = {}
      for (let i of this.list) {
        console.info('i', i)

        // 验证信息
        if (i._errorTips) {
          this.$toast(i._errorTips)
          return false
        }

        const { keyCode, value } = i

        // 识别value是否和入参一样，不一样才更改
        const propsItem = this.details.find(i => i.keyCode === keyCode)
        if (!propsItem) continue

        // if (JSON.stringify(propsItem.value) === JSON.stringify(value)) continue
        if (isEqual(propsItem.value, value)) continue
        if (value == '') continue
        obj[keyCode] = value
      }

      // 如果obj为空，则直接关闭
      if (isEmpty(obj)) {
        this.$emit('ok', this.ids)
        return false
      }

      const params = {
        leadId: this.leadId,
        labelMap: obj
      }

      const res = await clueServices.updateCluesTags(params).finally(() => loading.hideLoading())
      if (!res) {
        this.$toast(this.$t('修改失败'))
        return false
      }

      this.$toast.success(this.$t('修改成功'))
      this.$emit('ok', this.ids)
    },
    // 更新list items的数据
    UPDATELISTITEMS(keyName, value) {
      const items = this.list.filter((item) => { return keyName.includes(item.keyCode) })
      items.forEach(item => {
        this.$set(item, 'hidden', value)
      })
    },
    // 选取渠道来源
    onClueSourceSelect(item) {
      if (!this.changeClueChannel) return false
      selectClueSource({ noStrictly: true }).then((data) => {
        const res = data.value
        let permission = {}
        res && res.forEach((item, index) => {
          switch (index) {
            case 0:
              permission['channelOneId'] = item.id
              permission['channelOne'] = item.text
              break
            case 1:
              permission['channelTwoId'] = item.id
              permission['channelTwo'] = item.text
              break
            case 2:
              permission['channelThreeId'] = item.id
              permission['channelThree'] = item.text
              break
            case 3:
              permission['channelFourId'] = item.id
              permission['channelFour'] = item.text
              break
            case 4:
              permission['channelFiveId'] = item.id
              permission['channelFive'] = item.text
              break
          }
        })
        item.value = permission
      }).catch((err) => console.log(err))
    },
  }
}
</script>

<style lang="less" scoped>
.BasicInformation {
  background-color: #f6f6f6;
  min-height: 100vh;
  max-height: 100vh;
  padding: 16px;
  padding-bottom: 100px;
  overflow-y: auto;
  &.BasicInformation_n{
    padding: 0px;
    height: auto;
    min-height: fit-content;
    max-height: fit-content;
    background: #fff;
  }

  .body {
    background-color: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05); 
    border-radius: 8px 8px 8px 8px;
    .title-box{
      display: flex;
      align-items: center;
      .title {
        height: 48px;
        line-height: 48px;
        margin: 0 12px;
        flex: 1;
        font-size: 16px;
        color: #0D171A;
        border-bottom: 1px solid rgba(13,23,26,0.05);
        // text-transform: capitalize;
      }
    }

    .ul {
      border-radius: 8px;
      padding-bottom: 10px;

      &::after{
        position: initial !important;
      }
      .van-cell {
        border-radius: 8px;
      }

      /deep/ .van-field {
        .van-cell__title, .van-field__label {
          color: #0D171A !important;
          span {
            color: #0D171A !important;
          }
        }
      }

      /deep/ .van-cell__value {
        color: #0D171A !important;
      }

      .tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-bottom: -8px;
        .tag {
          background: #F5F5F5;
          border-radius: 4px 4px 4px 4px;
          text-align: center;
          font-size: 12px;
          color: rgba(13,23,26,0.45);
          margin-right: 8px;
          padding-left: 8px;
          padding-right: 8px;
          margin-bottom: 8px;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            background-color: #f7ecc8;
            color: #b9921a;
          }
        }
      }

      .tags2 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // padding-bottom: 10px;
        padding-left: 15px;

        .tag {
          height: 20px;
          line-height: 20px;
          background-color: #f8f4e8;
          color: #b9921a;
          font-size: 13px;
          padding: 0 8px;
          margin-right: 8px;
          border-radius: 2px;
          margin-top: 8px;
        }
      }

      .hr {
        margin: 4px 0 2px 0;
        margin-left: 15px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        &::before {
          content: ' ';
          background-color: #B9921A;
          width: 3px;
          height: 12px;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }
  
  .footer-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: #fff;

    .button {
      flex: 1;
      text-align: center;
      height: 42px;
      line-height: 42px;
      background: #FFFFFF;
      border-radius: 100px 100px 100px 100px;
      border: 1px solid #0D171A;
      margin-right: 8px;

      &.submit {
        background-color: #EED484;
        border-color: #EED484;
      }
    }
  }
}
</style>