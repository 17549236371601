<!--
* @Author: tianzl
* @Date: 2023-11-27 15:55:47
* @LastEditors: tianzl
* @LastEditTime: 2023-11-27 15:55:47
* @Description: 订单修改
 -->
 <template>
<span>
  <div>
    <span class="mr8 event-type">{{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}</span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('订单号')}}</span>
    <span class="value">{{ trip.extendInfo.appOrderId }}</span>
    <van-icon :name="isHidden?'arrow-down':'arrow-up'" size="12" class="ml22" @click="isHidden = !isHidden"/>
  </div>
  <template v-if="!isHidden">
    <div>
      <span class="label mr8">{{$t('修改信息修改前')}}</span>
    </div>
    <!-- before是数组 -->
    <div v-for="(item,idx) in before" :key="'before'+idx" class="ml22">
      <!-- item是对象 -->
      <div v-for="(value, key) in item" :key="key" >
        <span class="label mr8">{{ key }}</span>
        <span v-if="Array.isArray(value)" class="value">
          <span v-if="!value.length"></span>
          <span v-else-if="typeof value[0]==='string'">{{ value.join('、') }}</span>
          <div v-else-if="typeof value[0]==='object'" class="ml22">
            <div v-for="(subItem,subIdx) in value" :key="subIdx">
              <div v-for="(subVal, subKey) in subItem" :key="subKey">
                <span class="label mr8">{{ subKey }}</span>
                <span class="value">{{ subVal }}</span>
              </div>
            </div>
          </div>
        </span>
        <span v-else class="value">{{ value }}</span>
      </div>
    </div>
    <div>
      <span class="label mr8">{{$t('修改信息修改后')}}</span>
    </div>
    <div v-for="(afterItem,afterIdx) in after" :key="'after'+afterIdx" class="ml22">
      <div v-for="(afterValue, afterKey) in afterItem" :key="'aft'+afterKey" >
        <span class="label mr8">{{ afterKey }}</span>
        <span v-if="Array.isArray(afterValue)" class="value">
          <span v-if="!afterValue.length"></span>
          <span v-else-if="typeof afterValue[0]==='string'">{{ afterValue.join('、') }}</span>
          <div v-else-if="typeof afterValue[0]==='object'" class="ml22">
            <div v-for="(subAfterItem,subAfterIdx) in afterValue" :key="subAfterIdx">
              <div v-for="(subAfterVal, subAfterKey) in subAfterItem" :key="subAfterKey">
                <span class="label mr8">{{ subAfterKey }}</span>
                <span class="value">{{ subAfterVal }}</span>
              </div>
            </div>
          </div>
        </span>
        <span v-else class="value">{{ afterValue }}</span>
      </div>
    </div>
  </template>
</span>
</template>
<script>
let vm
export default {
  filters:{
    moneyStr(val){
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + vm.$t('元')
    },
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data(){
    return {
      isHidden: true
    }
  },
  computed:{
    before(){
      return this.trip.extendInfo.before
    },
    after(){
      return this.trip.extendInfo.after
    }
  },
  mounted(){
    vm=this
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";

.ml22{
  margin-left: 22px;
}
</style>