<script>
import dayjs from 'dayjs'
import { dateFormat,padTime,debounce, getNearHour } from '@/utils'
const num = 10
export default {
  props:{
    time:{
      type: Array,
      default:()=>([])
    },
    addMinute:{
      type:Number,
      default:30
    },
    startMinHour:{
      type: [Number,String],
      default: 0
    },
    startEndHour: {
      type: [Number,String],
      default: 23
    },
    startMinMinute:{
      type: [Number,String],
      default: 0
    },
    startEndMinute:{
      type: [Number,String],
      default: 0
    },
    endMinHour:{
      type: [Number,String],
      default: 0
    },
    endMaxHours:{
      type: [Number,String],
      default: 0
    },
    endMinMinute:{
      type: [Number,String],
      default: 0
    },
    endMaxMinutes:{
      type: [Number,String],
      default: 0
    },
    /*
      *是否有固定的开始 结束时间
     */
    regular: {
      type: Boolean,
      default: false
    },
    timeInterval:{
      type: Number,
      default: 10
    },
    // 开始时间选择范围
    startRange: {
      type: Array,
      default: ['00:00','23:50']
    },
    // 结束时间选择范围
    endRange: {
      type: Array,
      default: ['00:00','23:50']
    },
  },
  data() {
    return {
      startTime:'',
      endTime:'',
      date:'',
      endMinHours:0,
      endMinMinutes:0
    }
  },
  computed:{
    
  },
  watch:{
    time:{
      handler(val){
        const { dateFormat } = this
        if (Array.isArray(val)){
          const [one='',two=''] = val
          this.$nextTick(()=>{
            this.date = dateFormat(one)
            this.startTime = dateFormat(padTime(one),'HH:mm')
            this.endTime = dateFormat(padTime(two),'HH:mm')
          })
        }
      },
      deep: true,
      immediate: true
    },    
    endMinHour:{
      handler(val){
        this.endMinHours = val
      },
      immediate: true,
    },
    endMinMinute:{
      handler(val){
        this.endMinMinutes = val
      },
      immediate: true,
    }
  },
  methods: {
    dateFormat,
    changeTime() {
      const { startTime , endTime } = this
      this.$emit('change',[
        startTime ? startTime + ':00' : '',
        endTime ? endTime + ':00' : ''
      ])     
    },
    changeStart() {
      // 获取选择的开始时间
      let [selectH,selectM] = this.$refs.startTime.getPicker().getValues()
      const select = dayjs().hour(selectH).minute(selectM).second(0)
      const [limitStartH,limitStartM] = this.startRange[1].split(':')   
      const limitStart = dayjs().hour(this.startEndHour).minute(limitStartM).second(0)
      // const now = dayjs()
      // 选择的时间不超过限制时间，则直接用选择的时间，超过则用限制的最大时间
      // if (select.isSame(now,'date')&&select.isBefore(now)){
      //   console.log(this.startMinHour,this.startMinMinute)
      //   const date = dayjs(getNearHour(new Date()))
      //   selectH = date.format('HH')
      //   selectM = date.format('mm')
      //   this.$set(this,'startTime',[selectH,selectM].join(':'))
      // } else 
      if (select.isBefore(limitStart)){
        // this.startTime = [selectH,selectM].join(':')
      } else {
        selectH = this.startEndHour
        selectM = limitStartM
        // this.startTime = [selectH,selectM].join(':')
        
      }
      
      // 联动结束时间 23:50 00:00
      const computedEnd = dayjs().hour(selectH).minute(selectM).second(0).add(this.timeInterval,'minute')
      const [limitEndH,limitEndM] = this.endRange[1].split(':')   
      const limitEnd = dayjs().hour(limitEndH).minute(limitEndM).second(0)
      let endTime = computedEnd.format('HH:mm')
      // 联动的结束时间如果超过限制时间，也设置为最大结束时间
      if (!computedEnd.isBefore(limitEnd)){
        endTime = limitEnd.format('HH:mm')
      }
      this.$nextTick(()=>{
        this.$set(this,'startTime',[selectH,selectM].join(':'))
        this.$set(this,'endTime',endTime)
        this.changeTime()
      })
    },
    // 当时间小于最小时间时，取最小时间
    getOrTime(minTime,timeStr) {
      const { h,m } = minTime
      if (Number(h) || Number(m)){  
        const time = timeStr.split(':')
        if (Number(time[0]) < Number(h) || (Number(time[0]) === Number(h) && Number(time[1]) < Number(m))){
          timeStr = `${String(h).padStart(2,'0')}:${String(m).padStart(2,'0')}`
        }
      }
      return timeStr
    },
    getStartTimeVal(){
      return this.$refs.startTime.getPicker().getValues().join(':')
    },
    getEndTimeVal(){
      return this.$refs.endTime.getPicker().getValues().join(':')
    },
    changeEnd() {
      // 获取选择的结束时间
      let [endH,endminute] = this.$refs.endTime.getPicker().getValues()
      const selectEnd = dayjs().hour(endH).minute(endminute).second(0)
      const [limitEndH,limitEndM] = this.endRange[1].split(':')
      const limitEnd = dayjs().hour(limitEndH).minute(limitEndM).second(0)
      // 是否超过最晚结束时间
      if (selectEnd.isAfter(limitEnd)){
        endH = limitEndH
        endminute = limitEndM
      }
      const endTime = [endH,endminute].join(':')
      this.$nextTick(()=>{
        this.endTime = endTime
        this.endTime && this.changeTime()  
      })
    },
    getEndTime(startTime) {
      const nDate = new Date()
      const time =dayjs(dateFormat(nDate,'YYYY-MM-DD')+' '+ startTime+':00').add(this.addMinute, 'minute')
      let endTime = ''
      if (this.regular && ['17:30', '17:40', '17:50'].includes(startTime)) {
        endTime = '18:00'
      } else {
        endTime = dayjs(nDate).isSame(time, 'date') ? dateFormat(time,'HH:mm') : '23:50'
      }
      return endTime
    },
    filter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % this.timeInterval === 0)
      }
      return options
    },
  },
}
</script>
<template>
  <van-row >    
    <van-col span="12">
      <div class="title">{{ $t('开始') }}</div>
      <!-- {{ startTime }}{{ startMinHour }}{{ startMinMinute }} -->
      <!-- v-model="startTime" -->
      <van-datetime-picker
        ref="startTime"
        :value="startTime"
        type="time"
        :title="$t('选择时间')"
        :filter="filter"
        :show-toolbar="false"
        visible-item-count="3"
        :min-hour="startMinHour"
        :max-hour="startEndHour"
        :min-minute="startMinMinute"
        :max-minute="startEndMinute"
        @change="changeStart"
      />
    </van-col>
    <van-col span="12">
      <div class="title">{{ $t('结束') }}</div>
       <!-- {{ endTime }}{{ endMinHours }}{{ endMinMinutes }} -->
      <!-- v-model="endTime" -->
      <van-datetime-picker
        ref="endTime"
        :value="endTime"
        type="time"
        :title="$t('选择时间')"
        :filter="filter"
        :show-toolbar="false"
        visible-item-count="3"
        :min-hour="endMinHours"
        :max-hour="regular ? startEndHour + 1 : endMaxHours"
        :min-minute="endMinMinutes"
        :max-minute="regular && endTime?.split(':')[0] == '18' ? 0 : endMaxMinutes"
        @change="changeEnd"
      />
   </van-col>
  </van-row>  
</template>
<style lang="less" scoped>

.title{
    text-align: center;
  }
</style>
