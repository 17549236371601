<template>
  <div>
    <div>
      <span class="mr8 event-type">
        {{ trip.eventType }}
      </span>
      <span class="event-type">{{ trip.followUserName }}</span>
    </div>
    <div>
      <span class="label mr8">{{ $t("解绑微信") }}</span>
      <span class="value" style="flex: 1">
        <span>{{ trip.sysRemark }}</span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    trip: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style scoped lang="less">
@import "../style/common.less";
</style>
