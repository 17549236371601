<!--
* @Author: tianzl
* @Date: 2023-10-19 17:39:42
* @LastEditors: tianzl
* @LastEditTime: 2023-10-19 17:39:42
* @Description: 新建订单
 -->
 <template>
<span>
  <div>
    <span class="mr8 event-type">
      {{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}
    </span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('订单号')}}</span>
    <span class="value">
      {{ trip.extendInfo.appOrderId }}
    </span>
  </div>
  <div style="display: flex;">
    <span class="label mr8">{{$t('车型')}}</span>
    <span class="value" style="flex:1">{{ trip.extendInfo.seriesName }} {{ trip.extendInfo.modelName }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('总金额')}}</span>
    <span class="value">{{ trip.extendInfo.totalAmount | moneyStr }}</span>
  </div>
</span>
</template>
<script>
let vm
export default {
  filters:{
    moneyStr(val){
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + vm.$t('元')
    },
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  },
  created(){
    vm = this
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
</style>