<!--
* @Author: tianzl
* @Date: 2023-10-19 17:39:42
* @LastEditors: tianzl
* @LastEditTime: 2023-10-19 17:39:42
* @Description: 取消预约 
 -->
 <template>
<span>
  <div>
    <span class="mr8 event-type">{{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}</span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div v-if="trip.eventTypeCode==='1022014'">
    <span class="label mr8">{{$t('取消记录')}}</span>
    <span class="value">{{$t('超时自动取消')}}</span>
  </div>
  <!-- 战败取消预约时，不展示这些字段 -->
  <template v-if="trip?.extendInfo?.cancelSource!=='DEFEAT'">
    <div v-if="trip.eventTypeCode!=='1022014'">
      <span class="label mr8">{{$t('跟进方式')}}</span>
      <span class="value">{{ trip.followMode | getdectText(trip, 'followMode', $store,'1010') }}</span>
    </div>
    <div v-if="trip.eventTypeCode!=='1022014'">
      <span class="label mr8">{{$t('跟进结果')}}</span>
      <span class="value">{{ trip.followResult | getdectText(trip, 'followResult', $store,'1011') }}</span>
    </div>
    <div>
      <span class="label mr8">{{$t('试驾等级')}}</span>
      <span class="value">{{ trip.tryDriveLevel | getdectText(trip, 'tryDriveLevel', $store,'1012') }}</span>
    </div>
    <div>
      <span class="label mr8">{{$t('意向等级')}}</span>
      <span class="value">{{ trip.leadLevel || '--' }}</span>
    </div>
  </template>
  <div v-if="trip.eventTypeCode!=='1022014'" style="display: flex;">
    <span class="label mr8">{{$t('跟进记录')}}</span>
    <span class="value" style="flex:1">{{ trip.followRemark }}</span>
  </div>
</span>
</template>
<script>
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
</style>