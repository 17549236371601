<!--
* @Author: tianzl
* @Date: 2023-11-09 15:30:31
* @LastEditors: tianzl
* @LastEditTime: 2023-11-09 15:30:31
* @Description: 店内接待
 -->
 <template>
<span>
  <div>
    <span class="mr8 event-type">{{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}</span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('跟进方式')}}</span>
    <span class="value">{{ trip.followMode | getdectText(trip, 'followMode', $store,'1010') }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('跟进结果')}}</span>
    <span class="value">{{ trip.followResult | getdectText(trip, 'followResult', $store,'1011') }}
      <span v-if="trip.followResult==='1011004'">
        {{ trip.extendInfo.appointmentTypeCode==='2001001'? `| ${ $t('预约试驾')}` : `| ${ $t('预约到店') }` }}
      </span>
    </span>
  </div>
  <div>
    <span class="label mr8">{{$t('到店时间')}}</span>
    <span class="value">{{ trip?.storeOfTime ? trip.storeOfTime.split(' ')[1].substring(0,5):''  }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('到店人数')}}</span>
    <span class="value">{{ trip?.personCount ? trip.personCount: '' }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('是否参加活动')}}</span>
    <!-- 1是0否 -->
    <span class="value">{{ trip?.isActivity ? trip.isActivity==1?$t('是'):$t('否') : '' }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('试驾等级')}}</span>
    <span class="value">{{ trip.tryDriveLevel | getdectText(trip, 'tryDriveLevel', $store,'1012') }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('意向等级')}}</span>
    <span class="value">{{ trip.leadLevel || '--' }}</span>
  </div>
  <!-- 跟进结果为客户预约时展示 -->
  <div v-if="trip.followResult==='1011004'" style="display: flex;">
    <span class="label mr8">{{$t('预约信息')}}</span>
    <span class="value" style="flex:1">
      <div>{{ trip.extendInfo.appointmentTime }}</div>
      <div v-if="trip.extendInfo.appointmentTypeCode==='2001001'">
        {{ trip.extendInfo.vehicleName }}{{ trip.extendInfo.vehicleName&&trip.extendInfo.carNumber?' | ':'' }}{{ trip.extendInfo.carNumber }}
      </div>
    </span>
  </div>
  <div>
    <span class="label mr8">{{$t('跟进记录')}}</span>
    <span class="value">{{ trip.followRemark || '--' }}</span>
  </div>
  <!-- 跟进结果为修改预约时展示 -->
  <template v-if="trip.followResult==='1011006'">
    <div v-if="!isHide" style="display: flex;">
      <span class="label mr8" style="width: 60px;min-width:60px">{{$t('预约信息（修改前）')}}</span>
      <span class="value" style="flex:1">
        <div>{{ trip.extendInfo.beforeTrip.appointmentTypeName }}</div>
        <div>{{ trip.extendInfo.beforeTrip.appointmentTime }}</div>
        <div v-if="trip.extendInfo.beforeTrip.appointmentTypeCode==='2001001'">
          {{ trip.extendInfo.beforeTrip.vehicleName }}{{ trip.extendInfo.beforeTrip.vehicleName&&trip.extendInfo.beforeTrip.carNumber?' | ':'' }}{{ trip.extendInfo.beforeTrip.carNumber }}
        </div>
      </span>
    </div>
    <div v-if="!isHide" style="display: flex;">
      <span class="label mr8" style="width: 60px;min-width:60px ;">{{$t('预约信息（修改后）')}}</span>
      <span class="value" style="flex:1">
        <div>{{ trip.extendInfo.appointmentTypeName }}</div>
        <div>{{ trip.extendInfo.appointmentTime }}</div>
        <div v-if="trip.extendInfo.appointmentTypeCode==='2001001'">
          {{ trip.extendInfo.vehicleName }}{{ trip.extendInfo.vehicleName&&trip.extendInfo.carNumber?' | ':'' }}{{ trip.extendInfo.carNumber }}
        </div>
      </span>
    </div>
  </template>
</span>
</template>
<script>
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  },
  computed:{
    // 预约到店/试驾，如果预约时间和车系一模一样，则不展示
    isHide(){
      if (this.trip?.extendInfo){
        const { beforeTrip,appointmentTypeName,appointmentTime,vehicleName,carNumber } = this.trip.extendInfo
        return beforeTrip.appointmentTypeName === appointmentTypeName
        && beforeTrip.appointmentTime === appointmentTime
        && (beforeTrip.vehicleName === vehicleName || (!beforeTrip.vehicleName && !vehicleName))
        && (beforeTrip.carNumber === carNumber || (!beforeTrip.carNumber && !carNumber))
      }
      return true
    }
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
</style>