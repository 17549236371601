<!--
* @Author: tianzl
* @Date: 2023-12-11 13:35:55
* @LastEditors: tianzl
* @LastEditTime: 2023-12-11 13:35:55
* @Description: 分配到店
 -->
 <template>
<span>
  <div>
    <span class="mr8 event-type">{{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}</span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('跟进方式')}}</span>
    <span class="value">{{ trip.followMode | getdectText(trip, 'followMode', $store,'1010') }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('分配门店')}}</span>
    <span class="value">{{ trip.sysRemark }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('跟进记录')}}</span>
    <span class="value" style="flex:1">{{ trip.followRemark }}</span>
  </div>
</span>
</template>
<script>
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
</style>