import { queryTestDriveUpdate, showReceiveGift, editReceiveGift } from '@/services/carManageService'
import { debounce } from '@/utils'
export default {
  filters: {
    getdectText(val, trip, field, store, dictCode) {
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field] && val !== 0) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item => item.value == trip[field])
      return theOne?.label || ''
    }
  },
  methods: {
    // 开始试驾 && 确认出席
    getNone(appointments = {}) {
      setTimeout(() => {
        let appid = 'wxdafaf0480578a1b3'
        // 预约单
        const { vehicleCode: modelCode, vinCode, typeCode, type, id: appointId, leadId, customerMobile: appointmentPhone, testDriveActivityId, dealerId } = appointments
        if (process.env.VUE_APP_ENV !== 'uat') appid = 'wx25e0b11c7aaf8139'
        if (!appid) return false
        window.wx.invoke('launchMiniprogram', {
          'appid': appid,
          'path': `pages/index/index?modelCode=${modelCode}&vinCode=${vinCode}&type=${typeCode || type}&appointId=${appointId}&leadId=${leadId}&appointmentPhone=${appointmentPhone}&appointmentActivityId=${testDriveActivityId}&fromAppointment=true&dealerId=${dealerId}`
        }, (res) => {
          // console.log(res)
          if (res.err_msg === 'launchMiniprogram:ok') {
            console.log('跳转成功')
          } else {
            this.$toast.fail('无法跳转')
          }
        })
      }, 500)
    },
    // queryUpdate(driveRecordId, hasgift = false) {
    //   queryTestDriveUpdate({ driveId: driveRecordId, status: 5 }).then(res => {
    //     this.$toast(this.$t('结束试驾成功'))
    //     if (hasgift) {
    //       // 礼物领取之后 跳转到 C端试驾报告
    //       this.$router.push({
    //         path: '/test-drive-report',
    //         query: {
    //           driveRecordId
    //         }
    //       })
    //       return
    //     }
    //     window.location.reload()
    //   })
    // },
    // 试驾完成
    testDriveUpdate({ driveRecordId }) {
      if (!driveRecordId) return
      // 先获取礼物领取配置
      // const res = await showReceiveGift({ id: driveRecordId })
      // if (res) {
      //   this.$dialog.confirm({
      //     title: this.$t('提示'),
      //     message: this.$t('请确认用户是否已线下领取礼物！'),
      //     confirmButtonColor: '#B9921A',
      //     confirmButtonText: this.$t('线上领取'),
      //     cancelButtonText: this.$t('线下领取'),
      //   }).then(async () => {
      //     await editReceiveGift({ id: driveRecordId, receiveGift: 2 })
      //     this.queryUpdate(driveRecordId, true)
      //   }).catch(async () => {
      //     await editReceiveGift({ id: driveRecordId, receiveGift: 1 })
      //     this.queryUpdate(driveRecordId, true)
      //   })
      // } else {
      //   this.queryUpdate(driveRecordId)
      // }
      queryTestDriveUpdate({ driveId: driveRecordId, status: 5 }).then(res => {
        this.$toast(this.$t('结束试驾成功'))
        window.location.reload()
      })
    }
  }
}
