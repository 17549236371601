<template>
  <div>
    <div>
      <span class="mr8 event-type">
        {{ trip.eventType }}
      </span>
      <span class="event-type">{{ trip.followUserName }}</span>
    </div>
    <div>
      <span class="label mr8">{{ $t("客户信息") }}</span>
      <span class="value" style="flex: 1">
        <span>{{ trip.extendInfo.userName }}</span>
        <span style="margin: 0 5px">|</span>
        <span>{{ trip.extendInfo.userMobile }}</span>
      </span>
    </div>
    <div>
      <span class="label mr8">{{ $t("改派前门店") }}</span>
      <span class="value" style="flex: 1">{{
        trip.extendInfo.orgDealerName
      }}</span>
    </div>
    <div>
      <span class="label mr8">{{ $t("改派后门店") }}</span>
      <span class="value" style="flex: 1">{{
        trip.extendInfo.targetDealerName
      }}</span>
    </div>
    <template v-if="trip.eventTypeCode === '1022077'">
      <div>
        <span class="label mr8">{{ $t("改派原因") }}</span>
        <span class="value" style="flex: 1">{{ getReason() }}</span>
      </div>
      <div>
        <span class="label mr8">{{ $t("改派描述") }}</span>
        <span class="value" style="flex: 1">{{ trip.followRemark }}</span>
      </div>
    </template>
    <div v-else-if="trip.eventTypeCode === '1022078'">
      <span class="label mr8">{{ $t("审核结果") }}</span>
      <span class="value" style="flex: 1">{{ trip.sysRemark }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    trip: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getReason() {
      const { transferReason = '' } = this.trip.extendInfo
      const arr = transferReason.split(',')
      const brr = []
      const array = this.$store.getters.getDictHash(7001)
      for (let index = 0; index < array.length; index++) {
        const { code, name } = array[index]
        if (arr.includes(code)) {
          brr.push(name)
          if (arr.length === brr.length) {
            return brr.join()
          }
        }
      }
      return brr.join()
    },
  },
}
</script>
<style scoped lang="less">
@import "../style/common.less";
</style>
