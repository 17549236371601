<script>
export default {
  components: {},
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: () => {
        return {
          nodeKey: 'code',
          nodeName: 'name',
        }
      }
    }
  },
  data() {
    return {
      activeValue: ''
    }
  },
  watch: {
    modelValue: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.activeValue = val
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() { },
  methods: {
    handlerItem(item) {
      this.activeValue = item[this.config.nodeKey]
      this.$emit('change', item[this.config.nodeKey],item)
    }
  }
}
</script>
<template>
<div class="adviser">
  <div class="options">
    <div v-for="(item, index) in dataList" :key="item[config.nodeKey]" class="option"
      :class="activeValue == item[config.nodeKey] ? 'active' : ''" @click="handlerItem(item)">{{
        item[config.nodeName] }}</div>
  </div>
</div>
</template>

<style lang="less" scoped>
.adviser {
  width: 100%;

  .options {
    width: 100%;
    max-height: 260px;
    overflow-y: auto;

    .option {
      padding: 0 12px;
      background-color: #E7E7E7;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      display: inline-block;
      text-align: left;
      line-height: 36px;
      margin-right: 2%;
      margin-bottom: 12px;
      border-radius: 3px;
    }

    .active {
      background-color: #EED484;
    }
  }
}
</style>
