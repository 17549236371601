<script>
import appointmentMixin from '@/modules/appointment/mixin'
import { dateFormat } from '@/utils'
import dayjs from 'dayjs'
export default {
  mixins:[appointmentMixin],
  props:{
    item: {
      type: Object,
      default:()=>({})
    },
    info: {
      type: Object,
      default:()=>({})
    }
  },
  data(){
    return {
    }
  },
  computed:{
    isShowConfirmBtn() {
      const { appointmentStartTime } = this.item
      if (dayjs().isAfter(dayjs(appointmentStartTime))) {
        // 当前时间在预约试驾时间之后，不显示
        return false
      }
      return true
    },
    getUserInfo() {
      return this.$store.getters.userInfo
    },
  },
  methods:{
    dateFormat,
    getStatusShowBtn(item){
      const { followTime } = this.info
      const { status,actualEndTime } = item
      // 预约试驾状态(0: 已取消、1: 待确认、2: 已确认、3: 试驾中 9 已完成)
      // 跟进时间小于试驾完成时间
      // 若预约单已经试驾，但还未做过做过试驾回访的预约单也要显示 （此时不显示下方的快捷入口按钮）
      // 不是待分配数据
      return this.info.state != '2000000' && actualEndTime && followTime ? !(status === 9 && dayjs(followTime).isBefore(dayjs(actualEndTime),'minute')): true
    },
    goFollow(data,followResult='', followMode='',selfStatus='') {
      this.$router.push({
        path: '/clue-follow',
        query: {
          id: data.leadId,
          appointmentId: data.id,
          followResult, // '1011005', // 1011005取消预约  1011006修改预约
          followMode,
          selfStatus,
        }
      })
    },
    showEndDriveBtn({ driveEndTime }) {
      const now = dayjs().format('YYYY-MM-DD')
      const driveEnd = dayjs(driveEndTime).format('YYYY-MM-DD')
      const diffDay = dayjs(now).diff(dayjs(driveEnd), 'day')
      return driveEndTime && diffDay >= 1
      // return driveEndTime && dayjs().isBefore(dayjs(driveEndTime).add(2,'day'),'day')
    },
    goSchedule(item){
      this.$emit('goSchedule',item)
    },
    goAppointmentDetails(){
      const { id,leadId,appointmentTypeCode } = this.item
      // 预约到店
      this.$router.push({
        name:appointmentTypeCode === '2001002'?'appointmentDetail':'appointmentTestDriveDetail',
        query:{
          id: id,
          clueId: leadId
        }
      })
    }
  }
}
</script>
<template>
  <div class="card appointment" @click="goAppointmentDetails">
    <div class="title">
      {{$t('预约信息')}} - {{item.appointmentTypeCode === '2001002' ? $t('预约到店') : $t(item.typeName)}}
      <span v-if="item.appointmentTypeCode === '2001001'" class="status">{{item.tableType === 'TO_BE_CONFIRM'? $t('排程待确认') : item.tableType | getdectText(item, 'tableType', $store,'2022')}}</span>
      <span v-if="item.appointmentTypeCode === '2001002'" class="status">{{item.tableType | getdectText(item, 'tableType', $store,'2020')}}</span>
    </div>
    <div class="info">
      <template v-if="item.appointmentTypeCode === '2001002'">
        <div>
          <span class="label">{{$t('预约时间')}}</span>
          {{dateFormat(item.appointmentStartTime,'YYYY-MM-DD HH:mm')}} - {{dateFormat(item.appointmentEndTime,'HH:mm')}}
        </div>
        <div>
          <span class="label">{{$t('试驾类型')}}</span> {{ item.appointmentWay | codeFormat }}
        </div>
      </template>
      <template v-else>
        <div>
          <span class="label">{{$t('试驾车辆')}}</span>
          <span v-if="!(item.vehicleName || item.vehicleName)">--</span>
          <span v-else>{{item.seriesName}}{{item.seriesName&&item.vehicleName?' | ': ''}}{{item.vehicleName}}</span> 
          <span v-if="item.carNumber" class="carNumber">{{ item.carNumber }}</span>
        </div>
        <div v-if="item.typeCode==='2002002'">
        <span class="label">{{ $t('试驾地点') }}： </span>
        <span>{{ item.driveProvinceName }}{{ item.driveCityName }}{{ item.driveDistrictName }}{{ item.driveAddress }}</span>
      </div>
        <div>
          <span class="label">{{$t('预约试驾时间') }}</span>
          {{dateFormat(item.appointmentStartTime,'YYYY-MM-DD HH:mm')}} - {{dateFormat(item.appointmentEndTime,'HH:mm')}}
        </div>
        <div v-if="item.status === 9">
          <span class="label">{{$t('实际试驾时间') }}</span>
          {{dateFormat(item.driveStartTime,'YYYY-MM-DD HH:mm')}} - {{dateFormat(item.driveEndTime,'HH:mm')}}
        </div>
      </template>
    </div>
    <div v-if="getUserInfo.type == 1 && getStatusShowBtn(item) && !['CANCELED','NOSHOW'].includes(item.tableType) && item.assignStatus !== 0 && info.followUserRoleCode && info.followUserId" class="card-btns">
      <template v-if="item.appointmentTypeCode === '2001001'">
        <!-- <template v-if="((getUserInfo.authorizeIds||[]).includes(info?.followUserId) || getUserInfo.id == info.authorizedEmployeeId) && (getUserInfo.roleLogos||[]).includes(info.followUserRoleCode)"> -->
            <!-- 非上门试驾的 -->
            <template v-if="item.typeCode !== '2002002'">
              <!-- 待确认 -->
              <template v-if="item.status === 1">
                <van-button round class="btn" type="default" @click="goFollow(item,'1011005', '1010001')">{{ $t('取消试驾') }}</van-button>
                <van-button  round class="btn" type="default" @click="goFollow(item,'1011006', '1010001')">{{ $t('修改试驾') }}</van-button>
              </template>
              <!-- 待试驾 -->
              <template v-if="item.status === 2">
                <van-button round class="btn" type="default" @click="goFollow(item,'1011005', '1010001')">{{ $t('取消试驾') }}</van-button>
                <van-button v-if="isShowConfirmBtn" round class="btn" type="primary" @click="goFollow(item,'','','meet')">{{ $t('确认出席') }}</van-button>
                <van-button round class="btn" type="primary" @click.stop="getNone(item)">{{ $t('开始试驾') }}</van-button>
              </template>
              <!-- 试驾中 -->
              <van-button v-if="item.status === 3" round class="btn" type="primary" @click.stop="testDriveUpdate(item)">{{ $t('结束试驾') }}</van-button>
              <!-- 已完成 && 试驾结束小于当前时间 天-->
              <van-button v-if="item.status === 9 && showEndDriveBtn(item) && !item.tryDriveVisitStatus" round class="btn" type="primary" @click="goFollow(item)">{{ $t('试驾回访') }}</van-button>
            </template>
            <!-- 上门试驾的逻辑 -->
            <template v-if="['CONFIRMED','TIMEOUT_CONFIRMED','TO_BE_CONFIRM'].includes(item.appointmentStatusCode)&&item.typeCode === '2002002'">
              <template v-if="item.status !== 0">
                <!-- 店长角色并且预约单状态是待审核 -->
                <template v-if="(getUserInfo?.roleLogos.includes('1014003') || getUserInfo?.roleLogos.includes('1014004'))&&item.reviewStatus==='0'&&item.tableType!=='TIMEOUT_CANCEL'">
                  <van-button round class="btn" type="primary" @click.stop="$emit('openReview',item)">{{ $t('审核') }}</van-button>
                </template>
                <template v-if="item.reviewStatus!=='0'">
                  <van-button round class="btn" type="default" @click="goFollow(item,'1011005', '1010001')">{{ $t('取消试驾') }}</van-button>
                  <!-- 审核被驳回时 -->
                  <van-button v-if="item.reviewStatus==='1'" round class="btn" type="default" @click="goFollow(item,'1011006', '1010001')">{{ $t('修改试驾') }}</van-button>
                  <!-- 排程 -->
                  <van-button v-if="item.reviewStatus==='2'&&item.status!==2" round class="btn" type="default" @click.stop="goSchedule(item)">{{ $t('预约排程') }}</van-button>
                  <!-- 确认出席 -->
                  <van-button v-if="item.reviewStatus==='2'&&item.status===2&&isShowConfirmBtn"  round class="btn" type="primary"  @click="goFollow(item,'','','meet')">{{ $t('确认出席') }}</van-button>
                  <!-- 开始试驾 -->
                  <van-button v-if="item.status===2" round class="btn" type="primary" @click.stop="getNone(item)">{{ $t('开始试驾') }}</van-button>
                </template>
              </template>
            <!-- </template> -->
          </template>
      </template>
      <template v-if="item.appointmentTypeCode === '2001002' && ['NO_ARRIVAL','TIMEOUT_NO_ARRIVAL'].includes(item.tableType)">
        <van-button type="default" @click="goFollow(item,'1011005', '1010001')">{{ $t('取消预约') }}</van-button>
        <van-button type="default" @click="goFollow(item,'1011006', '1010001')">{{ $t('修改预约') }}</van-button>
        <van-button type="default" @click="goFollow(item,'1011002','1010003')">{{ $t('到店接待') }}</van-button>
      </template>
    </div>
  </div>
</template>
<style lang="less" scoped>
.appointment{
  cursor: pointer;
    .title{
      margin-bottom: 12px;
      border-bottom: 1px solid #F3F3F4;
      padding-bottom: 12px;
      .status{
        padding: 2px 5px;
        background: @yellow;
        color: #fff;
        font-size: 12px;
        border-radius: 6px;
        margin-left: 4px;
        &.active{
          background: #00C800;
        }
      }
    }
    .info{
      font-size: 13px;
      color:#0D171A;
      .label{
        margin-right: 12px;
      }
      >div{
        margin-bottom: 8px;
      }
    }
    .card-btns{
      text-align: right;
      /deep/ .van-button{
        height: 26px;
        line-height: normal;
        border-radius: 4px;
        margin-left: 12px;
        .van-button__text{
          font-size: 12px;
        }
      }
    }
  }
</style>
