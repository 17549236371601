<!--
* @Author: tianzl
* @Date: 2023-10-19 17:39:42
* @LastEditors: tianzl
* @LastEditTime: 2023-10-19 17:39:42
* @Description: 修改预约
 -->
 <template>
<span>
  <div>
    <span class="mr8 event-type">{{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}</span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div v-if="trip.eventTypeCode === '1022011'">
    <span class="label mr8">{{$t('跟进方式')}}</span>
    <span class="value">{{ trip.followMode | getdectText(trip, 'followMode', $store,'1010') }}</span>
  </div>
  <div v-if="trip.eventTypeCode === '1022011'">
    <span class="label mr8">{{$t('跟进结果')}}</span>
    <span class="value">{{ trip.followResult | getdectText(trip, 'followResult', $store,'1011') }}</span>
  </div>
  <div v-if="trip.eventTypeCode === '1022013'">
    <span class="label mr8">{{$t('修改方式')}}</span>
    <span class="value">{{ trip.sysRemark }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('试驾等级')}}</span>
    <span class="value">{{ trip.tryDriveLevel | getdectText(trip, 'tryDriveLevel', $store,'1012') }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('意向等级')}}</span>
    <span class="value">{{ trip.leadLevel || '--' }}</span>
  </div>
  <div v-if="!isHide" style="display: flex;">
    <span class="label mr8" style="width: 75px;min-width: 60px;">{{$t('预约信息修改前')}}</span>
    <span class="value" style="flex:1">
      <div>{{ trip.extendInfo.beforeTrip.appointmentTypeCode==='2001001'?$t(trip.extendInfo.beforeTrip.typeName):$t('预约到店') }}</div>
      <div>{{ trip.extendInfo.beforeTrip.appointmentTime }}</div>
      <!-- 预约试驾 -->
      <template v-if="trip.extendInfo.beforeTrip.appointmentTypeCode==='2001001'">
        <!-- 集中试驾 展示活动名称,车系 -->
        <template v-if="trip.extendInfo.beforeTrip.type === '2002003'">
          <div>
            {{ trip.extendInfo.beforeTrip.activityTitle||'--' }}
          </div>
          <div>
            {{ trip.extendInfo.beforeTrip.seriesName||'--' }}
          </div>
        </template>
        <div v-if="trip.extendInfo.beforeTrip.type !== '2002003'">
          {{ trip.extendInfo.beforeTrip.vehicleName }}{{ trip.extendInfo.beforeTrip.vehicleName&&trip.extendInfo.beforeTrip.carNumber?' | ':'' }}{{ trip.extendInfo.beforeTrip.carNumber }}
        </div>
        <!-- 上门试驾，展示地址 -->
        <div v-if="trip.extendInfo.beforeTrip.type === '2002002'">
          {{ trip.extendInfo.beforeTrip | addressFilter }}
        </div>
      </template>
    </span>
  </div>
  <div v-if="!isHide" style="display: flex;">
    <span class="label mr8" style="width: 75px;min-width:60px ;">{{$t('预约信息修改后')}}</span>
    <span class="value" style="flex:1">
      <div>{{ trip.extendInfo.appointmentTypeCode==='2001001'?$t(trip.extendInfo.typeName):trip.extendInfo.appointmentTypeName }}</div>
      <div>{{ trip.extendInfo.appointmentTime }}</div>
      <template v-if="trip.extendInfo.appointmentTypeCode==='2001001'">
        <!-- 集中试驾 展示活动名称,车系 -->
        <template v-if="trip.extendInfo.type === '2002003'">
          <div>
            {{ trip.extendInfo.activityTitle||'--' }}
          </div>
          <div>
            {{ trip.extendInfo.seriesName||'--' }}
          </div>
        </template>
        <div v-if="trip.extendInfo.type !== '2002003'">
          {{ trip.extendInfo.vehicleName }}{{ trip.extendInfo.vehicleName&&trip.extendInfo.carNumber?' | ':'' }}{{ trip.extendInfo.carNumber }}
        </div>
         <!-- 上门试驾，展示地址 -->
         <div v-if="trip.extendInfo.type === '2002002'">
          {{ trip.extendInfo | addressFilter }}
        </div>
      </template>
    </span>
  </div>
  <div v-if="trip.eventTypeCode === '1022013'">
    <span class="label mr8">{{$t('预约状态')}}</span>
    <span class="value">{{ trip.extendInfo.statusName }}</span>
  </div>
  <!-- 上门试驾的需要展示b6d0e9 -->
  <div v-if="trip.extendInfo.appointmentTypeCode==='2001001'&&trip.extendInfo.type === '2002002'">
    <span class="label mr8">{{$t('审核状态')}}</span>
    <span class="value">{{ trip.extendInfo.reviewStatusName }}</span>
  </div>
</span>
</template>
<script>
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    },
    addressFilter(val){
      const { driveProvinceName,driveCityName,driveDistrictName,driveAddress } = val
      return `${driveProvinceName}${driveCityName}${driveDistrictName}${driveAddress}`
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  },
  computed:{
    // 预约到店/试驾，如果预约时间和车系一模一样，则不展示
    isHide(){
      const { beforeTrip,appointmentTypeName,appointmentTime,vehicleName,carNumber,type } = this.trip.extendInfo
      const flag = beforeTrip.appointmentTypeName === appointmentTypeName
      && beforeTrip.type === type
      && beforeTrip.appointmentTime === appointmentTime
      && (beforeTrip.vehicleName === vehicleName || (!beforeTrip.vehicleName && !vehicleName))
      && (beforeTrip.carNumber === carNumber || (!beforeTrip.carNumber && !carNumber))
      // 比较上门试驾的地址
      let flag2 = true
      if (beforeTrip.type===type&&type==='2002002'){
        const { driveProvinceName,driveCityName,driveDistrictName,driveAddress } = this.trip.extendInfo
        flag2 = beforeTrip.driveProvinceName===driveProvinceName
          &&beforeTrip.driveCityName===driveCityName
          &&beforeTrip.driveDistrictName===driveDistrictName
          &&beforeTrip.driveAddress===driveAddress
      }
      // 比较集中试驾的活动名称、车系
      let flag3 = true
      if (beforeTrip.type===type&&type==='2002003'){
        const { activityTitle,seriesName } = this.trip.extendInfo
        flag3 = beforeTrip.activityTitle===activityTitle
          && beforeTrip.seriesName===seriesName
      }
      return flag&&flag2&&flag3
    }
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
</style>