<!--
* @Author: tianzl
* @Date: 2023-10-19 17:39:42
* @LastEditors: tianzl
* @LastEditTime: 2023-10-19 17:39:42
* @Description: 客户预约
 -->
 <template>
<span>
  <div>
    <span class="mr8 event-type">{{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}</span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div v-if="trip.eventTypeCode==='1022007'&&trip.followMode">
    <span class="label mr8">{{$t('跟进方式')}}</span>
    <span class="value">{{ trip.followMode | getdectText(trip, 'followMode', $store,'1010') }}</span>
  </div>
  <div v-if="trip.eventTypeCode==='1022007'">
    <span class="label mr8">{{$t('跟进结果')}}</span>
    <span class="value">
      <span v-if="trip.followResult">{{ trip.followResult | getdectText(trip, 'followResult', $store,'1011') }}</span>
      <span v-if="trip.followResult&&(trip.extendInfo.appointmentTypeCode==='2001002'||trip.extendInfo.typeName)"> | </span>
      <span>{{ trip.extendInfo.appointmentTypeCode==='2001002' ? `${$t('预约到店')}` : $t(trip.extendInfo.typeName) }}</span>
    </span>
    </div>
  <div v-if="trip.eventTypeCode==='1022007'&&trip.tryDriveLevel">
    <span class="label mr8">{{$t('试驾等级')}}</span>
    <span class="value">{{ trip.tryDriveLevel | getdectText(trip, 'tryDriveLevel', $store,'1012') }}</span>
  </div>
  <div v-if="trip.eventTypeCode==='1022007'&&trip.leadLevel">
    <span class="label mr8">{{$t('意向等级')}}</span>
    <span class="value">{{ trip.leadLevel || '--' }}</span>
  </div>
  <!--  APP预约 -->
  <div v-if="trip.eventTypeCode==='1022015'">
    <span class="label mr8">{{$t('来源渠道')}}</span>
    <span class="value">{{ trip.channel }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('试驾类型')}}</span>
    <span class="value">{{ trip.extendInfo.appointmentMethod | getdectText(trip.extendInfo, 'appointmentMethod', $store,'2025') }}</span>
  </div>
  <!-- 预约试驾就有 预约时间+预约车系 -->
  <!-- 预约到店 预约时间 -->
  <div style="display: flex;">
    <span class="label mr8">{{$t('预约信息')}}</span>
    <!--  预约到店 -->
    <span class="value">
      <div>{{ trip.extendInfo.appointmentTime }}</div>
      <!-- 集中试驾，展示活动名称，预约车系 -->
      <template v-if="trip.extendInfo.type==='2002003'">
        <div class="value">
          {{ trip.extendInfo.activityTitle||'--' }}
        </div>
        <div class="value">
          {{ trip.extendInfo.seriesName||'--' }}
        </div>
      </template>
      <!-- 预约试驾 到店试驾和上门试驾展示车型&车牌号 -->
      <div v-if="trip.extendInfo.appointmentTypeCode==='2001001'&&trip.extendInfo.type!=='2002003'" class="value">
        {{ trip.extendInfo.vehicleName?trip.extendInfo.vehicleName:'' }}{{trip.extendInfo.vehicleName&&trip.extendInfo.carNumber?' | ':''  }}{{ trip.extendInfo.carNumber }}
      </div>
      <!-- 上门试驾 展示地址 -->
      <div v-if="trip.extendInfo.type==='2002002'" class="value">
        {{ trip.extendInfo.driveProvinceName }}{{trip.extendInfo.driveCityName}}{{ trip.extendInfo.driveDistrictName }}{{ trip.extendInfo.driveAddress }}
      </div>
    </span>
  </div>
  <!-- APP预约 -->
  <div v-if="trip.eventTypeCode==='1022015'">
    <span class="label mr8">{{$t('预约状态')}}</span>
    <span class="value">{{ trip.extendInfo.statusName }}</span>
  </div>
  <div v-if="trip.eventTypeCode==='1022007'&&trip.assignUserName">
    <span class="label mr8">{{$t('接待顾问')}}</span>
    <span class="value">{{ trip.assignUserName }}</span>
  </div>
  <div v-if="trip.eventTypeCode==='1022007'&&trip.followRemark" style="display: flex;">
    <span class="label mr8">{{$t('跟进记录')}}</span>
    <span class="value" style="flex:1">{{ trip.followRemark }}</span>
  </div>
</span>
</template>
<script>
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
</style>