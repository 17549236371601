<template>
  <div>
    <van-tabs
      v-if="origin !== 'app'"
      v-model="queryType"
      :ellipsis="false"
      @change="onChangeTab"
    >
      <van-tab name="1" :title="$t('标准话术')"></van-tab>
      <van-tab name="2" :title="$t('个人话术')"></van-tab>
      <van-tab name="3" :title="$t('收藏话术')"></van-tab>
    </van-tabs>
    <search-bar-new
      :placeholder-text="$t('按标题/内容/关键词搜索')"
      :showScreen="true"
      :active="filterActive"
      @screening="
        () => {
          !filterOptions.length && createFilterOptions();
          filterPanelVisible = true;
        }
      "
      @input="
        (val) => {
          searchStr = val;
        }
      "
      @search="onRefresh"
    >
    </search-bar-new>
    <!-- 筛选面板 -->
    <FilterPanel
      ref="filterPanel"
      v-model="filterPanelVisible"
      :options="filterOptions"
      @submit="onFilter"
    />
    <div class="type-box">
      <ul class="type">
        <li
          v-for="item in typeList"
          :key="item.type"
          :class="{
            active: types.includes(item.type),
          }"
          @click="onChangeType(item.type)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>

    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <div class="item-wrap" @click="goDetail(item)">
          <span v-if="item.topTime" class="back-top-tag">{{ "置顶" }}</span>
          <div class="top">
            <p class="title van-ellipsis">
              {{ item.title }}
            </p>
            <span class="time">
              {{ $moment(item.updateTime).format("YYYY-MM-DD") }}
            </span>
          </div>
          <p class="desc van-multi-ellipsis--l3">
            {{ item.content }}
          </p>
          <div style="display: flex; align-items: center;">
            <Tags
              style="margin-top: 5px;margin-right: auto"
              :item="item"
              :typeData="typeData"
              :tagConfig="tagConfig"
            ></Tags>
            <div
              style="margin-left: 10px; margin-top: 5px"
              @click.stop="onBackTop(item)"
            >
              <van-icon
                name="back-top"
                size="22"
                :style="!item.topTime ? '' : { transform: 'rotate(180deg)' }"
                :color="!item.topTime ? '#1989fa' : '#0D171A'"
              />
            </div>
            <div
              style="margin-left: 10px; margin-top: 5px"
              @click.stop="onStart(item)"
            >
              <van-icon
                v-if="item.collectFlag == '0' || item.collectFlag == null"
                size="22"
                name="star-o"
              />
              <van-icon v-else size="22" name="star" color="#B9921A" />
            </div>
          </div>
        </div>
      </template>
    </List>
    <!-- name: 当前选中标签的名称或索引值!!! -->
    <van-tabbar
      v-if="origin == 'app'" 
      v-model="queryType"
      placeholder
      @change="onChangeTab"
    >
      <van-tabbar-item name="1">{{ $t("标准话术") }}</van-tabbar-item>
      <van-tabbar-item name="2">{{ $t("个人话术") }}</van-tabbar-item>
      <van-tabbar-item name="3">{{ $t("收藏话术") }}</van-tabbar-item>
    </van-tabbar>
    <div class="add-wrap">
      <div
        v-if="queryType == 2"
        class="add"
        @click="$router.push('/talk-skill-edit')"
      >
        <svg
          t="1710923415651"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="5292"
          width="20"
          height="20"
        >
          <path
            d="M544 341.312a32 32 0 0 0-64 0V480H341.312a32 32 0 0 0 0 64H480v138.688a32 32 0 1 0 64 0V544h138.688a32 32 0 1 0 0-64H544V341.312z"
            fill="#ffffff"
            p-id="5293"
          ></path>
          <path
            d="M514.432 74.688h-4.864c-93.44 0-166.912 0-224.192 7.68-58.752 7.872-105.344 24.448-141.952 61.056-36.672 36.608-53.12 83.2-61.056 141.952-7.68 57.28-7.68 130.688-7.68 224.192v4.864c0 93.44 0 166.912 7.68 224.192 7.872 58.752 24.384 105.344 61.056 141.952 36.608 36.608 83.2 53.12 141.952 61.056 57.28 7.68 130.688 7.68 224.192 7.68h4.864c93.44 0 166.912 0 224.192-7.68 58.752-7.872 105.344-24.384 141.952-61.056 36.608-36.608 53.12-83.2 61.056-141.952 7.68-57.28 7.68-130.688 7.68-224.192v-4.864c0-93.44 0-166.912-7.68-224.192-7.872-58.752-24.384-105.344-61.056-141.952-36.608-36.672-83.2-53.12-141.952-61.056-57.28-7.68-130.688-7.68-224.192-7.68z m-325.76 113.92c22.72-22.72 53.568-35.84 105.216-42.816 52.544-7.04 121.664-7.104 218.112-7.104s165.568 0 218.112 7.104c51.648 6.976 82.496 20.096 105.216 42.88 22.784 22.72 35.904 53.568 42.88 105.216 7.04 52.544 7.104 121.664 7.104 218.112s0 165.568-7.104 218.112c-6.976 51.648-20.096 82.496-42.88 105.216-22.72 22.784-53.568 35.904-105.216 42.88-52.544 7.04-121.664 7.104-218.112 7.104s-165.568 0-218.112-7.104c-51.648-6.976-82.496-20.096-105.216-42.88-22.784-22.72-35.904-53.568-42.88-105.216-7.04-52.544-7.104-121.664-7.104-218.112s0-165.568 7.104-218.112c6.976-51.648 20.096-82.496 42.88-105.216z"
            fill="#ffffff"
            p-id="5294"
          ></path>
        </svg>
        <span>{{ $t("新增") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import SearchBarNew from '@/components/search-bar-new'
import List from '@/components/baseList/list.vue'
import FilterPanel from '@/components/filter-panel'
import loading from '@/utils/loading'
import {
  speechCraftPage,
  speechTop,
  topConutCheck,
  getPersonalTypeList,
} from '@/services/talk-skill.js'
import { v4 as uuidv4 } from 'uuid'
import mixins from './mixins.js'
import Tags from './tags'

export default {
  name: 'TalkSkill',
  components: {
    List,
    SearchBarNew,
    FilterPanel,
    Tags,
  },
  mixins: [mixins],
  props: ['origin'],
  data() {
    return {
      queryType: '1',
      types: [],
      filterPanelVisible: false,
      searchStr: '',
      totalCount: 0,
      filterOptions: [],
      loading: false,
      filterParams: {
        updateTime: {
          start: '',
          end: '',
        },
      },
      filterActive: false,
      personalTypeList: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'TalkSkill')
      if (['/talk-skill-details', '/talk-skill-edit'].includes(from.path)) {
        vm.onRefresh()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (!['/talk-skill-details', '/talk-skill-edit'].includes(to.path)) {
      this.$store.commit('app/deleteKeepAlive', 'TalkSkill')
    }
    next()
  },
  computed: {
    typeList() {
      if (this.queryType == 2 && this.personalTypeList.length) {
        return this.personalTypeList
      }
      return this.typeData.options
    },
  },
  watch: {
    $route: {
      handler(value) {
        if (value.path == '/clue-details') {
          this.onRefresh()
        }
      },
    },
  },
  created() {
    this.getTypeTree()
  },
  methods: {
    async onBackTop({ topTime, id }) {
      const params = {
        id,
        topFlag: topTime ? false : true,
        speechCraftType: this.queryType,
        topType: this.queryType,
      }
      if (params.topFlag) {
        loading.showLoading()
        const count = await topConutCheck({
          topType: this.queryType,
          topFlag: true,
          speechCraftType: this.queryType,
        })
        loading.hideLoading()
        if (count) {
          await this.$dialog.confirm({
            title: this.$t('提示'),
            message: this.$t(
              '已达到置顶话术上限，再次置顶将按操作时间自动更新已置顶话术，请确认!'
            ),
            confirmButtonColor: '#B9921A',
          })
        }
      }
      loading.showLoading()
      await speechTop(params)
      this.$toast(params.topFlag ? this.$t('已置顶') : this.$t('取消置顶'))
      setTimeout(() => {
        this.onRefresh()
      }, 300)
    },
    async onStart(item) {
      await this.onChangeStart(item)
      setTimeout(() => {
        this.onRefresh()
      }, 300)
    },
    // 组件搜索框数据
    async createFilterOptions() {
      let ret = [
        {
          id: uuidv4(),
          type: 'dataRange',
          dateType: 'datetime',
          label: this.$t('更新时间'),
          start: {
            field: 'start',
          },
          end: {
            field: 'end',
          },
        },
      ]
      // 车系车型，新版
      ret.push({
        id: uuidv4(),
        type: 'cars',
        label: this.$t('适用车型'),
        field: 'cars',
        tagsInput: true,
        fields: ['seriesCodes', 'modelCodes'],
      })

      this.filterOptions = ret
    },
    async onChangeTab(name) {
      if (name == 2 && !this.personalTypeList.length) {
        const list = await getPersonalTypeList()
        this.personalTypeList = list.map(({ name, id }) => ({
          name,
          type: id,
        }))
      }
      this.types = []
      this.onRefresh()
    },
    onChangeType(type) {
      if (this.types.includes(type)) {
        const index = this.types.findIndex((e) => e == type)
        this.types.splice(index, 1)
      } else {
        this.types.push(type)
      }
      this.onRefresh()
    },
    onRefresh() {
      this.$refs.list.onRefresh()
    },
    onFilter(params) {
      this.filterPanelVisible = false
      this.filterParams.seriesCodes = params.seriesCodes
        ? params.seriesCodes
        : []
      this.filterParams.modelCodes = params.modelCodes ? params.modelCodes : []
      this.filterParams.updateTime.start = params.start
        ? this.$moment(params.start).format('YYYY-MM-DD 00:00:00')
        : ''
      this.filterParams.updateTime.end = params.end
        ? this.$moment(params.end).format('YYYY-MM-DD 23:59:59')
        : ''

      this.filterActive = Boolean(
        this.filterParams.seriesCodes.length ||
          this.filterParams.modelCodes.length ||
          this.filterParams.updateTime.start ||
          this.filterParams.updateTime.end
      )
      this.onRefresh()
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const classifykey =
        this.queryType == 2 && this.personalTypeList.length
          ? 'personalType'
          : 'classifyOne'
      const params = {
        isQwQuery: true,
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        searchStr: this.searchStr,
        [classifykey]: this.types,
        queryType: this.queryType,
        ...this.filterParams,
      }

      loading.showLoading()
      return speechCraftPage(params).then((res) => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        return res
      })
    },
    goDetail({ id }) {
      this.$router.push({
        path: '/talk-skill-details',
        query: {
          id,
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/.van-tabbar--fixed {
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
}
.add-wrap {
  position: fixed;
  z-index: 1000;
  width: 100%;
  max-width: 500px;
  bottom: 160px;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  text-align: right;
}
.add {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #D3B42B;
  margin-right: 32px;
  span {
    margin-top: 4px;
    font-size: 12px;
    color: #fff;
  }
}
/deep/ .list {
  padding-bottom: 0;
}
.type-box {
  margin-top: 10px;
  ul {
    overflow-x: auto;
    display: flex;
    padding: 0 16px;
    li {
      text-align: center;
      padding: 2px 8px;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      line-height: 16px;
      font-size: 12px;
      min-width: 66px;
      flex-shrink: 0;
      &.active {
        color: #b9921a;
        background: rgba(238, 212, 132, 0.45);
        border-color: rgba(238, 212, 132, 0.45);
      }
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
.total {
  height: 22px;
  font-size: 14px;
  font-family: HYQiHei, HYQiHei;
  font-weight: normal;
  color: #262626;
  line-height: 22px;
  margin: 9px 16px 0;
}

.item-wrap {
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px 12px;
  .back-top-tag {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    border-radius: 0 8px 0 8px;
    background-color: #1989fa;
    // background-color: #fe2d46;
    color: white;
    padding: 0 6px;
    line-height: 16px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    :last-child {
      align-self: flex-end;
      flex-shrink: 0;
      margin-left: 8px;
    }
  }
  .title {
    // color: #323233;
    font-size: 14px;
    // font-weight: bold;
  }
  .time {
    font-size: 12px;
    color: rgba(13, 23, 26, 0.45);
  }
  .desc {
    color: rgba(13, 23, 26, 0.45);
    margin-top: 5px;
    font-size: 13px;
    line-height: 18px;
  }
  .tag-wrap {
    margin-left: -5px;
  }
}
</style>
