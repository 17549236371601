import Vue from 'vue'
import selectClueSource from './select-clue-source.vue'
import router from '@/router'

const SelectorConstructor = Vue.extend(selectClueSource)

let instance = null

export default (options) => {
  if (!instance) {
    instance = new SelectorConstructor({
      el: window.document.createElement('div')
    })
    window.document.body.appendChild(instance.$el)
  }
  router.afterEach(to => {
    if (instance){
      instance.reject()
      instance.showPicker=false
    }
  })
  return new Promise((resolve, reject) => {
    // noStrictly 是否选择级联（强关联到最后一级） 默认必须到最后一级  noStrictly：true 选择到任意一级
    instance.noStrictly = !!options.noStrictly
    instance.restrictions = options.restrictions
    instance.exceptions = options.exceptions
    instance.isOnlineChannel = options.isOnlineChannel
    instance.showAll = options.showAll
    instance.showPicker = true
    instance.resolve = resolve
    instance.reject = reject
  })
    .finally(() => {
      window.setTimeout(() => {
        instance.$el.remove()
        instance.$destroy()
        instance = null
      }, 200)
    })
}
