<!--
* @Author: tianzl
* @Date: 2023-10-31 10:33:27
* @LastEditors: tianzl
* @LastEditTime: 2023-10-31 10:33:27
* @Description: 无效审核
 -->
 <template>
<span>
  <div>
    <span class="mr8 event-type">
      {{ getdectText(trip.eventTypeCode, trip, 'eventTypeCode', $store, '1022') }}
    </span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('审核结果')}}</span>
    <!--<span class="value">{{ trip.followResult | getdectText(trip, 'followResult', $store,'1011') }}{{ trip.sysRemark ? ` | ${trip.sysRemark}` : '' }}</span>-->
    <span v-if="getdectText(trip.followResult, trip, 'followResult', $store, '1011')" class="value">{{ getdectText(trip.followResult, trip, 'followResult', $store, '1011') }}
      <span v-if="['1022023'].includes(trip.eventTypeCode)">{{ trip.sysRemark ? ` | ${trip.sysRemark}` : ''  }}</span>
    </span>
    <span v-else class="value">{{ trip.sysRemark }}</span>
  </div>
  <div style="display: flex;">
    <span class="label mr8">{{$t('审核备注')}}</span>
    <span class="value" style="flex:1">{{ trip.followRemark }}</span>
  </div>
</span>
</template>
<script>
export default {
  filters:{
    // getdectText(val,trip,field,store,dictCode){
    //   // 不需要查字典
    //   if (!dictCode) return val
    //   // 没有值的情况
    //   if (!trip[field]) return '--'
    //   const dictHash = store.getters.dictHash
    //   const getOptionsByDictType = (dictType) => {
    //     const list = dictHash[dictType] || []
    //     return list.map(({ code, id, name }) => ({
    //       id,
    //       label: name,
    //       value: code,
    //     }))
    //   }
    //   const options = getOptionsByDictType(dictCode)
    //   const theOne = options.find(item=>item.value === trip[field])
    //   return theOne?.label || ''
    // }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
</style>