import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import Promise from 'vue-resource/src/lib/promise'

const ClueDefeatServices = {
  /**
   * 战败审核
   */
  async failedApproval(params) {
    const result = await request.post(`${SERVER.LEAD_SERVICE}/api/v1/leadFail/review`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },

  /**
   * 获取线索列表
   */
  async getclueList(params) {
    const result = await request.post(`${SERVER.LEAD_SERVICE}/api/v1/leadFail/list`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },

  // 战败原因分类列表
  async getDefeatReasonListonList() {
    const result = await request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/defeatClue/typeList`)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },

  // 战败原因子类列表
  async getDefeatReasonListonChildrenList(params){
    const result = await request.post(`${SERVER.BASE_DATA_SERVICE}/api/v1/defeatClue/list`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  }
}

export default ClueDefeatServices
