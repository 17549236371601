<template>
  <form-select-wrap-cascader
    :columns="list"
    :latestChannels="latestChannels"
    :showPicker="showPicker"
    :title="i18n.t('请选择')"
    :loading="loading"
    :empty="empty"
    :listFromOut="listFromOut"
    @confirm="onConfirm"
    @cancel="cancel"
    @cellChange="cellChange"
  >
  </form-select-wrap-cascader>
</template>

<script>
import baseDataServices from '@/services/baseDataServices'
import clueServices from '@/services/clueServices'
import formSelectWrapCascader from './form-select-wrap-cascader'
import { Toast } from 'vant'
import { i18n } from '@/main'
export default {
  components: {
    formSelectWrapCascader,
  },
  resolve: null,
  reject: null,
  data() {
    return {
      index0: 0,
      index1: 0,
      index2: 0,
      index3: 0,
      list: [],
      loading: false,
      showPicker: false,
      listFromOut: false,
      empty: false,
      restrictions: [[], [], [], [], []],
      exceptions: [[], [], [], [], []],
      showAll: false,
      resolveList: null,
      i18n,
      latestChannels: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.list.length === 0) {
        this.onGetRootSource()
      }
    })
  },
  methods: {
    getLatestChannels() {
      clueServices.getLatestChannels().then((res)=>{
        this.latestChannels = res.splice(0, 3)
      })
    },
    // 获取一级节点
    async onGetRootSource() {
      this.loading = true
      // 获取每级渠道来源
      const res2 = await this.getCascadeSourceList('1').catch(err => console.log(err))
      // 一级菜单只能为“终端开发”或“自然到店”
      this.list = [ ...res2 ]
      this.getLatestChannels()
    },
    async getCascadeSourceList(channelId='', level = 0, isLast = false, needLoading=true) {
      if (needLoading) this.loading = true
      const res = await baseDataServices.getChannelListById({ channelId }).catch(err => console.log(err))
      this.loading = false
      let tempArr = res.map(item => {
        const { name: text,channelKey, id, online } = item
        const temp = { text, channelKey, id, online }
        !isLast && (temp.children = [])
        return temp
      })
      return tempArr
    },
    // cellChange
    async cellChange(data) {
      const { level, index, active: { id: channelId, text, channelKey } } = data
      if (level === 0) {
        this.index0 = index
        // 获取二级节点
        let secondLevelList = await this.getCascadeSourceList(channelId, level + 1).catch(err => console.log(err))
        if (secondLevelList.length > 0) {
          this.list[this.index0].children = [ ...secondLevelList ]
        }
      } else if (level === 1) {
        this.index1 = index
        // 获取三级节点
        const thirdLevelList = await this.getCascadeSourceList(channelId, level + 1).catch(err => console.log(err))
        thirdLevelList.length > 0 && (this.list[this.index0].children[this.index1].children = thirdLevelList)
      } else if (level === 2) {
        this.index2 = index
        // 获取四级节点
        const fourLevelList = await this.getCascadeSourceList(channelId, level + 1).catch(err => console.log(err))
        fourLevelList.length > 0 && (this.list[this.index0].children[this.index1].children[this.index2].children = [ ...fourLevelList ])
      } else if (level === 3) {
        this.index3 = index
        // 获取五级节点
        const fiveLevelList = await this.getCascadeSourceList(channelId, level + 1, true, false).catch(err => console.log(err))
        fiveLevelList.length > 0 && ((this.list[this.index0].children[this.index1].children[this.index2].children[this.index3].children = [ ...fiveLevelList ]))
      }
    },
    onConfirm(value) {

      if (value.length > 0) {
        if (!this.noStrictly && value[value.length - 1].children && value[value.length - 1].children.length > 0) {
          Toast('请完成全部选择')
          return
        }
      }
      this.resolve({ value, resolveList: this.resolveList })
      this.showPicker = false
    },
    cancel() {
      this.reject('cancel')
      this.showPicker = false
    },
  },
}
</script>

<style>
</style>
