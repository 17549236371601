<script>
import dayjs from 'dayjs'
import calendarVue from './calendar.vue'
import datetimeRangeVue from './datetimeRange.vue'
import { dateFormat, padTime, getTimeNowOrOld, getNearHour } from '@/utils'
export default {
  components: { calendarVue, datetimeRangeVue },
  props: {
    date: {
      type: Array,
      default: () => ([])
    },
    maxHeight: {
      type: String,
      default: '320px'
    },
    showNum: {
      type: Boolean,
      default: false
    },
    fromPdi: {
      type: Boolean,
      default: false
    },
    /*
      *是否有固定的开始 结束时间
     */
    regular: {
      type: Boolean,
      default: false
    },
    minDate:{
      type: Date,
      default: ()=> dayjs().subtract(12, 'month').toDate()
    },
    maxDate:{
      type: Date,
      default: ()=> dayjs().add(12, 'month').toDate()
    },
    // 时间间隔，试驾是间隔 30 分钟，其他的是间隔 10 分钟
    timeInterval:{
      type: Number,
      default: 10
    },
    // 开始时间选择范围
    startRange: {
      type: Array,
      default: ['00:00','23:50']
    },
    // 结束时间选择范围
    endRange: {
      type: Array,
      default: ['00:00','23:50']
    },
  },
  data() {
    return {
      start: '',
      end: '',
    }
  },
  computed: {
    startMin() {
      return this.startMinFun(this.start)
    },
    // 
    startMax(){
      const maxTime = this.startRange.length===2 && this.startRange[1]
      const [h,m] = maxTime.split(':')
      const result = {
        h,
        m: 59 // 默认最大分钟数是 59
      }
      const nDate = dayjs()
      const nowH = dateFormat(nDate,'HH')
      const selectH = dateFormat(this.start,'HH')

      if ((this.start && nDate.isSame(dayjs(dateFormat(this.start)), 'date') && (nowH>=h)) || selectH>=h){
        result.m = m
      }
      console.log(result,'startMax')
      return result
    },
    endMin() {
      const { startMin, end } = this
      return this.endMinFun(startMin, end)
    },
    endMax(){
      const maxTime = this.endRange.length===2 && this.endRange[1]
      const [h,m] = maxTime.split(':')
      const result = {
        h,
        m: 59
      }
      const nDate = dayjs()
      const nowH = dateFormat(nDate,'HH')
      const selectH = dateFormat(this.end,'HH')
      if ((this.start && nDate.isSame(dayjs(dateFormat(this.start)), 'date') && (nowH>=h)) || selectH>=h){
        result.m = m 
      }
      console.log(result,'endMax')
      return result
    },
    calendarMinDate(){
      if (this.fromPdi){
        return dayjs().toDate()
      }
      return this.minDate
    },
    calendarMaxDate(){
      if (this.fromPdi){
        return dayjs().add(1, 'month').toDate()
      }
      return this.maxDate
    }
  },
  watch: {
    date: {
      handler(val) {
        if (Array.isArray(val)) {
          const [one = '', two = ''] = val
          this.start = one
          this.end = two
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    dateFormat,
    // 计算最小开始时间 
    // 如果时间是今天，已过去的小时/分钟不能被选择
    // 如果时间是未来，根据 startRange 限制计算
    startMinFun(start) {
      // 最小的开始时间
      const [minTime,maxTime] = this.startRange
      const [minHour,minMinute] = minTime.split(':')
      // 最晚的开始时间
      const [maxHour,maxMinute] = maxTime.split(':')
      const minStart = dayjs().hour(minHour).minute(minMinute).second(0)
      const maxStart = dayjs().hour(maxHour).minute(maxMinute).second(0)

      const result = {
        h: minHour,
        m: 0
      }
      // 选择的时间是今日
      const nDate = dayjs()
      if (start && nDate.isSame(dayjs(dateFormat(start)), 'date')) {
        const h = Number(dateFormat(nDate, 'HH'))
        const m = Number(dateFormat(nDate, 'mm'))
        // 超过最晚时间
        if (!nDate.isBefore(maxStart,'minute')){
          result.h = maxHour
          result.m = maxMinute
        }
        // 在限制时间范围内
        if (!nDate.isBefore(minStart,'minute') && !nDate.isAfter(maxStart,'minute')){
          if (m+this.timeInterval>=60){
            result.h = h+1 > maxHour ? maxHour : (h+1)
            result.m = h+1 > maxHour ? maxMinute : 0
          } else {
            result.h = h
            if (dayjs(start).format('HH')>h){
              result.m = 0
            } else {
              if (this.timeInterval===10){
                result.m = dayjs(padTime(nDate)).format('mm')
              } else if (this.timeInterval===30){
                result.m = dayjs(getNearHour(nDate)).format('mm')
              }
            }
            
          }
        }
      }
      console.log(result,'startMin')
      return result
    },
    // 计算最小结束时间
    // 如果时间是今天，已过去的小时/分钟不能被选择  endRange [09:00, 24:00] 16:00
    // 如果时间是未来，根据 endRange 限制计算
    endMinFun(startMin, end) {
      const [minTime,maxTime] = this.endRange
      // 最小的结束时间
      const [minHour,minMinute] = minTime.split(':')
      // 最晚的结束时间
      const [maxHour,maxMinute] = maxTime.split(':')

      const minEnd = dayjs().hour(minHour).minute(minMinute).second(0)
      const maxEnd = dayjs().hour(maxHour).minute(maxMinute).second(0)
      const result = {
        h: minHour,
        m: 0
      }
      const nDate = dayjs()
      const h = Number(dateFormat(nDate,'HH'))
      const m = Number(dateFormat(nDate,'mm'))
      if (end && nDate.isSame(dayjs(dateFormat(end)), 'date')) {
        // 超过最晚时间
        if (!nDate.isBefore(maxEnd,'minute')){
          result.h = maxHour
          result.m = maxMinute
        }
        // 在限制时间范围内
        if (!nDate.isBefore(minEnd,'minute') && !nDate.isAfter(maxEnd,'minute')){
          if (m+this.timeInterval>=60){
            result.h = h+1 > maxHour ? maxHour : (h+1)
            result.m = h+1 > maxHour ? maxMinute : 0
          } else {
            result.h = h
            if (dayjs(end).format('HH')>h){
              result.m = 0
            } else {
              if (this.timeInterval===10){
                result.m = dayjs(padTime(nDate)).format('mm')
              } else if (this.timeInterval===30){
                result.m = dayjs(getNearHour(nDate)).format('mm')
              }
            }
          }
        }
        // if (h>maxHour){
        //   result.h = maxHour
        // } else if (h<minHour){
        //   result.h = minHour
        // } else {
        //   if (m+this.timeInterval>=60){
        //     result.h = h+1>maxHour?maxHour:h+1
        //   } else {
        //     result.h = h
        //   }
        //   // 如果选择的小时等于当前小时，那最小分钟为当前分钟
        //   if (h===Number(dateFormat(end, 'HH'))&&(h!=maxHour)){
        //     result.m = m
        //   }
        // }
         
        console.log(result,'endMin')
        return result
      }
      return result
    },
    changeDate(val) {
      const { start, end } = this
      let date = dateFormat(val)
      const timeObj = getTimeNowOrOld(new Date(`${date} ${dateFormat(start, 'HH:mm:ss')}`), new Date(`${date} ${dateFormat(end, 'HH:mm:ss')}`))
      const nowEndTime = this.$refs.datetimeRange.getEndTime(dateFormat(timeObj.start, 'HH:mm'))
      this.$emit('change', [
        new Date(timeObj.start), new Date(`${date} ${nowEndTime}:59`)
      ], 'date')
    },
    changeTime(val) {
      const { start, end } = this
      const [one, two] = val
      if (one && two) {
        const date = dateFormat(start)
        this.$emit('change', [
          new Date(`${date} ${one}`),
          new Date(`${date} ${two}`)
        ], 'time')
      }
    },
    // 修改开始时间的范围
    // changeRange(val){
    //   this.
    // }
  }
}
</script>
<template>
  <div class="box">
    <calendarVue
      :minDate="calendarMinDate"
      :maxDate="calendarMaxDate"
      :fromPdi="fromPdi"
      :isAutoScrollTop="true"
      :disabledHistory="true" 
      :regular="regular"
      v-bind="$attrs"
      :initDate="start"
      :showNum="showNum"
      :maxHeight="maxHeight"
      @change="changeDate" />
      <!--   -->
    <datetimeRangeVue ref="datetimeRange" style="margin-top: 20px;" :time="[start, end]"
      :startMinHour="regular ? 9 : startMin.h" 
      :startEndHour="regular ? 17 : startMax.h" 
      :startMinMinute="startMin.m" 
      :startEndMinute="startMax.m"
      :regular="regular"
      :endMinHour="endMin.h" 
      :endMinMinute="endMin.m"
      :endMaxHours="endMax.h"
      :endMaxMinutes="endMax.m"
      :timeInterval="timeInterval"
      :startRange="startRange"
      :endRange="endRange"
      @change="changeTime" />
  </div>
</template>
<style lang="less" scoped>
.box {
  background-color: #fff;

}
</style>

