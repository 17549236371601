<script>
export default {
  props:{
  }
}
</script>
<template>
<van-tag class="tag" type="primary" v-bind="$attrs"><slot></slot></van-tag>
</template>
<style lang="less" scoped>
.tag{
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 1;
  &/deep/.van-tag--plain{
    color: #316EE2;
    border-color: #316EE2;
  }
}
</style>
