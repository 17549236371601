<script>
import Adviser from './adviser.vue'
import { mapGetters } from 'vuex'
import baseDataServices from '@/services/baseDataServices'
export default {
  components: { Adviser },
  props: {
    // 角色类型
    applyUserRole: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        reviewStatus: '',
        reviewRemark: '',
        assignUserId: ''
      },
      empList: [],
      dictList: []
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
  },
  watch: {
    dictHash: {
      immediate: true,
      handler(val) {
        if (val) {
          this.dictList = this.getDictNameByCode(1013, '', 'array').filter(item => item.code !== '1013001')
        }
      },
    },
    userInfo: {
      handler(val) {
        if (val) this.init()
      },
      immediate: true
    }
  },
  methods: {
    getData() {
      return {
        reviewStatus: this.form.reviewStatus,
        reviewRemark: this.form.reviewRemark,
        assignUserId: this.form.assignUserId,
        assignUserName: this.empList.find(item => item.id === this.form.assignUserId)?.name
      }
    },
    async init() {
      this.empList = await baseDataServices.getStaffList({ roleLogo: this.applyUserRole, dealerId: this.userInfo.dealers[0]?.id })
    },
    async validate() {
      return this.$refs.formRef.validate()
    }
  }
}
</script>
<template>
<div class="defeat-from">
  <van-form ref="formRef" :show-error="false">

    <van-field v-model="form.reviewStatus" required :name="$t('审核结果')" style="display: block;" :label="$t('审核结果')"
      :rules="[{ required: true, message: $t('请选择审核结果') }]">
      <template #input>
        <van-radio-group v-model="form.reviewStatus" direction="horizontal">
          <van-radio v-for="item in dictList" :key="item.code" :name="item.code">{{ item.name }}</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <van-field v-model="form.reviewRemark" :required="form.reviewStatus==='1013003'" style="display: block;" :name="$t('审核备注')" :label="$t('审核备注')"
      :placeholder="$t('请输入审核备注')" :rules="[{ required: form.reviewStatus==='1013003', message: $t('请输入审核备注') }]" maxlength="50" />

    <van-field v-if="form.reviewStatus == '1013004'" v-model="form.assignUserId" error required :name="$t('顾问选择')"
      style="display: block;" :label="$t('顾问选择')" :rules="[{ required: true, message: $t('请选择顾问') }]">
      <template #input>
        <Adviser v-model="form.assignUserId" :dataList="empList" :config="{ nodeKey: 'id', nodeName: 'name' }">
        </Adviser>
      </template>
    </van-field>

  </van-form>
</div>
</template>

<style lang="less" scoped>
.defeat-from {
  padding: 20px;
}
</style>
