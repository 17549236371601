/*
 * @Author: 张超越 
 * @Date: 2024-03-19 09:10:14 
 * @Last Modified by: 张超越
 * @Last Modified time: 2024-04-08 11:23:06
 */

<template>
  <div class="SelectTags">
    <div class="body">
      <div class="title-box">
        <div class="title">{{ title }}</div>
      </div>

      <div class="tags">
        <div v-for="(tag, index) of getTags" :key="index" :class="['tag', getSelectCodes.includes(tag.valueCode) ? 'active' : '']" @click="handleClickTag(tag, index)">
          {{ tag.valueName }}
        </div>
      </div>
    </div>

    <div class="footer-box">
      <div class="button" @click="handleClose">{{$t('返回')}}</div>
      <div class="button submit" @click="handleOK">{{ $t('确定') }}</div>
    </div>
  </div>
</template>

<script>
// APIs
import baseDataServices from '@/services/baseDataServices'
import clueServices from '@/services/clueServices'

// Tools
import loading from '@/utils/loading'

export default {
  name: 'SelectTags',
  props: {
    keyCode: {
      type: String,
      default: '',
    },
    details: {
      type: String,
      default: () => ''
    },
    leadId: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tags: [],
      ids: []
    }
  },
  computed: {
    getTags() {
      return this.list || []
    },
    getSelectCodes() {
      return this.ids.map(i => i.valueCode)
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.ids = this.details.split(';').map(i => {
        return { valueCode: i }
      })
    },
    handleClickTag(tag, index) {
      console.info('handleClickTag', tag)
      const idIndex = this.ids.findIndex(i => i.valueCode === tag.valueCode)
      console.info('idIndex', idIndex)

      if (idIndex >= 0) {
        this.ids.splice(idIndex, 1)
        return
      }

      this.ids.push(tag)
    },
    handleClose() {
      this.$emit('close')
    },
    async handleOK() {
      this.$emit('ok', this.getSelectCodes)
    }
  }
}
</script>

<style lang="less" scoped>
.SelectTags {
  background-color: #f6f6f6;
  min-height: 100vh;
  padding: 16px;

  .body {
    background-color: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05); 
    border-radius: 8px 8px 8px 8px;
    .title-box{
      display: flex;
      align-items: center;
      .title {
        height: 48px;
        line-height: 48px;
        margin: 0 12px;
        flex: 1;
        font-size: 16px;
        color: #0D171A;
        border-bottom: 1px solid rgba(13,23,26,0.05);
      }
    }

    .tags {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 12px;
      padding-bottom: calc( 12px - 8px );
      border-radius: 0 0 8px 8px;
      flex-wrap: wrap;
      .tag {
        background: #F5F5F5;
        border-radius: 4px 4px 4px 4px;
        margin-right: 12px;
        padding: 4px 8px;
        text-align: center;
        margin-bottom: 8px;

        &.active, &:active {
          background-color: #f7ecc8;
          color: #b9921a;
        }
      }
    }
  }
  
  .footer-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: #fff;

    .button {
      flex: 1;
      text-align: center;
      height: 42px;
      line-height: 42px;
      background: #FFFFFF;
      border-radius: 100px 100px 100px 100px;
      border: 1px solid #0D171A;
      margin-right: 8px;

      &.submit {
        background-color: #EED484;
        border-color: #EED484;
      }
    }
  }
}
</style>