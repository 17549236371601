<!--
* @Author: tianzl
* @Date: 2023-12-11 13:35:55
* @LastEditors: tianzl
* @LastEditTime: 2023-12-11 13:35:55
* @Description: 线索培育
 -->
 <template>
  <span>
    <div>
      <span class="mr8 event-type">
        {{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}
      </span>
    </div>
    <div>
      <span class="label mr8">{{$t('合并至')}}</span>
      <span class="value">{{ trip?.extendInfo.userMobile }} | {{ trip?.extendInfo.userName }}</span>
    </div>
    <div>
      <span class="label mr8">{{$t('线索ID')}}</span>
      <span class="value">{{ trip?.extendInfo.mergeToId }}</span>
    </div>
    <div>
      <span class="label mr8">{{$t('合并原因')}}</span>
      <span class="value" style="flex:1">{{ trip.followResult }}</span>
    </div>
    <div>
      <span class="label mr8">{{$t('备注')}}</span>
      <span class="value" style="flex:1">{{ trip.sysRemark}}</span>
    </div>
  </span>
  </template>
<script>
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
  <style scoped lang="less" >
  @import "../style/common.less";
  </style>