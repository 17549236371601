<!--
* @Author: tianzl
* @Date: 2023-10-19 17:39:42
* @LastEditors: tianzl
* @LastEditTime: 2023-10-19 17:39:42
* @Description: 上门试驾审核
 -->
 <template>
<span>
  <div>
    <span class="mr8 event-type">{{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}</span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('修改方式')}}</span>
    <span class="value">{{ trip.sysRemark }}</span>
  </div>
  <!-- 预约试驾就有 预约时间+预约车系 -->
  <!-- 预约到店 预约时间 -->
  <div style="display: flex;">
    <span class="label mr8">{{$t('预约信息')}}</span>
    <span class="value">
      <div>{{ trip.extendInfo.typeName }}</div>
      <div>{{ trip.extendInfo.appointmentTime }}</div>
      <!-- 预约试驾 到店试驾和上门试驾展示车型&车牌号 -->
      <div class="value">
        {{ trip.extendInfo.vehicleName?trip.extendInfo.vehicleName:'' }}{{trip.extendInfo.vehicleName&&trip.extendInfo.carNumber?' | ':''  }}{{ trip.extendInfo.carNumber }}
      </div>
      <!-- 上门试驾 展示地址 -->
      <div class="value">
        {{ trip.extendInfo.driveProvinceName }}{{trip.extendInfo.driveCityName}}{{ trip.extendInfo.driveDistrictName }}{{ trip.extendInfo.driveAddress }}
      </div>
    </span>
  </div>
  <div>
    <span class="label mr8">{{$t('审核状态')}}</span>
    <span class="value">{{ trip.extendInfo.reviewStatusName }}</span>
  </div>
</span>
</template>
<script>
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
</style>