import { render, staticRenderFns } from "./receive-public-leads.vue?vue&type=template&id=459c5a56&scoped=true"
import script from "./receive-public-leads.vue?vue&type=script&lang=js"
export * from "./receive-public-leads.vue?vue&type=script&lang=js"
import style0 from "./receive-public-leads.vue?vue&type=style&index=0&id=459c5a56&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "459c5a56",
  null
  
)

export default component.exports