<script>
import Tag from '@/components/card-view/tag.vue'
import IntentionLevel from '@/components/card-view/intention-level.vue'
import copyIcon from '@/images/copy.png'
import editIcon from '@/images/edit.png'
import defaultIcon from '@/images/default.png'
import girlIcon from '@/images/girl.png'
import boyIcon from '@/images/boy.png'
export default {
  components:{ Tag, IntentionLevel },
  props:{
    data:{
      type: Object,
      default:()=>({
        detail:{}
      })
    },
    // 是否展示0d5545
    isShowViMobile:{
      type: Boolean,
      default: false
    },
    // 电话号码是否脱敏
    isDesensitization: {
      type: [Boolean, undefined],
      default: false
    },
    // 线索详情调整，为了兼容线索培育
    sourcePage: {
      type: String,
      default:'clue-incubation'
    },
    isShowCopyMobile:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      copyIcon,editIcon,defaultIcon,girlIcon,boyIcon
    }
  },
  computed:{
    getSexIcon() {
      return {
        1: this.boyIcon,
        2: this.girlIcon
      }[this.data?.userSex] || ''
    },
    getName() {
      const name = this.data.userName
      return name ? name.substring(0,1): ''
    },
    getLevel() {
      const code = this.data.tryDriveLevel
      const obj = this.$store.getters.getDictHash(1012).find(item=>{
        return item.code === code
      })
      return obj && obj.name 
    },
    getUserInfo() {
      return this.$store.getters.userInfo
    },
  },
  methods:{
    copy() {
      wx.setClipboardData({
        data: this.data.userMobile, // 设置的
        success: function(res) {
          this.$toast.success(this.$t('手机号复制成功'))
        }
      })
    },
    desensitization(val, type) {
      if (!this.isDesensitization) return val
      if (!val) return ''
      let str = ''
      if (type === 'name' && val) {
        val = val.substring(0, 1) + '**'
        str = val
      }
      if (type === 'phone') {
        let reg = /^(1[3-9][0-9])\d{4}(\d{4}$)/
        if (val) str = val.replace(reg, '$1****$2')
      }
      return str
    },
  }
}
</script>
<template>
<div class="user">
  <div class="headImg">
    <!-- 头像 -->
    <img v-if="data.userAvatar" class="img" :src="defaultIcon" width="100%" />
    <div v-else>{{ getName }}</div>
    <img v-if="data.userSex" class="sex" :src="getSexIcon" />
  </div>
  <div class="info-r">
    <div class="info-r-1">
      <span class="name">{{data.userName}}</span>
      <template v-if="sourcePage === 'clue-incubation'">
        <template v-if="data.drivingTime || (!data.drivingTime && data.leadLevel === 'H')">
          <IntentionLevel v-if="data.leadLevel" :level="data.leadLevel"></IntentionLevel>
        </template>
        <template v-if="!data.drivingTime">
          <span v-if="data.tryDriveLevel" class="level">{{getLevel}}</span>
        </template>
      </template>
    </div>
    <div class="info-r-2">
      <span>{{ desensitization(data.userMobile,'phone') }}</span>
      <img v-if="isShowCopyMobile" :src="copyIcon" width="18px" @click.stop="copy"/>
    </div>
    <div class="info-r-3">
      <Tag v-if="[0,1].includes(data.detail.isMajorCustomer)" color="#F2F3FF" text-color="#316EE2">{{ data.detail.isMajorCustomer ? $t('大客户') : $t('普通客户') }}</Tag>
      <Tag v-if="data.detail.isReplace" color="#F2F3FF" text-color="#316EE2">{{$t('置换客户')}}</Tag>
    </div>
  </div>
</div>
</template>
<style lang="less" scoped>
.user{
  display: flex;
  .headImg{
    width:48px;
    height:48px;
    background-color: #E9F2FF;
    border-radius: 50%;
    text-align: center;
    line-height: 48px;
    color: #3476FE;
    font-size: 16px;
    margin-right:12px;
    position: relative;
    .sex{
      position: absolute;
      bottom: 0;
      right: 0;
      width: 16px;
      height: 16px;
    }
  }
  .info-r{
    font-size: 12px;
    flex: 1;
    >div:not(:last-child){
      margin-bottom: 10px;
    }
    .info-r-1,.info-r-2,.info-r-3{
      display: flex;
      align-items: center;
      >span{
        margin-right: 8px;
      }
      .more{
        color: rgba(13,23,26,0.45);
        font-size: 12px;
        margin-left: auto;
      }
    }
    .name{
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
    }
    .level{
      background-color: @yellow;
      border-radius: 8px;
      padding: 1px 4px;
      color: #0D171A;
      font-size: 10px;
    }
  }
}
</style>
