<script>
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import wxIcon from '@/components/card-view/wx-icon.vue'
import smsIcon from '@/components/card-view/sms-icon.vue'
export default {
  components:{ externalUserIcon, wxIcon,smsIcon },
  data() {
    return {
      list:[
        {
          name: this.$t('绑定客户微信'),
          componentName:'externalUserIcon'
        },
        // {
        //   name:'短信加微',
        //   componentName:'smsIcon'
        // },
        {
          name: this.$t('添加微信'),
          componentName:'wxIcon'
        }
      ]
    }
  }
}
</script>
<template>
<div class="btnIcons">
  <component :is="item.componentName" v-for="(item,index) in list" :key="index" v-bind="$attrs" class="icon" :name="item.name" v-on="$listeners"/>
</div>
</template>
<style lang="less" scoped>
.btnIcons{
  display: flex;
  justify-content: space-around;
  .icon{
    /deep/ img{
      width: 16px;
      height: 16px;
    }
  }
  
}
</style>
