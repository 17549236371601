<!--
* @Author: tianzl
* @Date: 2023-11-23 13:31:00
* @LastEditors: tianzl
* @LastEditTime: 2023-11-23 13:31:00
* @Description: 线索授权
 -->
 <template>
<span>
  <div>
    <span class="mr8 event-type">
      {{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1023') }}
    </span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('授权类型')}}</span>
    <span class="value">{{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1023') }}
    </span>
  </div>
  <div v-if="trip.eventTypeCode!=='1023003'" style="display: flex;">
    <span class="label mr8">{{$t('授权顾问')}}</span>
    <span class="value" style="flex:1">{{ trip.sysRemark }}</span>
  </div>
</span>
</template>
<script>
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
</style>