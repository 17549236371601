<script>
import smsImg from '@/images/sms.png'
export default {
  props:{
    name:{
      type: String,
      default:''
    }
  },
  data() {
    return {
      smsImg
    }
  },
  methods:{
    
  }
}
</script>
<template>
<div class="img" >
  <img :src="smsImg" />
  <span v-if="name" style="margin-left:8px;">{{ name }}</span>
</div>
</template>
<style lang="less" scoped>
.img{
  cursor: pointer;
  display: flex;
  align-items: center;
  img{
    width: 32px;
    height: 32px;
    &:hover,&:active{
      box-shadow: 0px 2px 5px #21212138;
      border-radius: 50%;
    }
  }
}
</style>
