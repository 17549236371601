import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'

export const speechCraftPage = (params) => {
  return request
    .post(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/speechCraft/page`, params)
    .then(thenResolve)
}
export const speechCraftDetail = async (params) => {
  return await request
    .get(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/speechCraft/detail`, { params })
    .then(thenResolve)
}
export const speechCraftAdd = (params) => {
  return request
    .post(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/speechCraft/add`, params)
    .then(thenResolve)
}
export const speechCraftUpdate = (params) => {
  return request
    .post(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/speechCraft/update`, params)
    .then(thenResolve)
}
export const speechCraftCollect = (params) => {
  return request
    .post(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/speechCraft/collect`, params)
    .then(thenResolve)
}
export const speechTop = (params) => {
  return request
    .post(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/speechCraft/speechTop`, params)
    .then(thenResolve)
}
export const topConutCheck = (params) => {
  return request
    .post(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/speechCraft/topCheck`, params)
    .then(thenResolve)
}
// 使用量浏览器埋点
export const speechCount = (params) => {
  return request
    .post(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/speechCraft/speechCount`, params)
    .then(thenResolve)
}

// 个人话术分类列表
export const getPersonalTypeList = (params) => {
  return request
    .post(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/personalType/list`, params)
    .then(thenResolve)
}

// 个人话术分类编辑
export const personalTypeSave = (params) => {
  return request
    .post(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/personalType/save`, params)
    .then(thenResolve)
}
export const standardTypeTree = (params = {}) => {
  return request.post(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/standardType/tree`, params).then(thenResolve)
}
export const copyToPersonal = (params = {}) => {
  return request.get(`${SERVER.SPEECHCRAFT_SERVICE}/api/v1/speechCraft/copy/to/personal`, { params }).then(thenResolve)
}