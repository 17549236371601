import loading from '@/utils/loading'
import { speechCraftCollect, standardTypeTree } from '@/services/talk-skill.js'
export default {
  data() {
    return {
      typeTree: [],
    }
  },
  computed: {
    typeData() {
      let config = {}
      const options = this.typeTree.map((item) => {
        config[item.id] = item.name
        return {
          name: item.name,
          type: item.id,
        }
      })
      return {
        options,
        config,
      }
    },
    tagConfig() {
      let config = {}
			this.$store.getters.getDictHash(6001)?.forEach((item) => {
			  config[item.code] = item.name
			})
			return config
    },
  },
  methods: {
    getTypeTree() {
      standardTypeTree().then((res) => {
        this.typeTree = res
      })
    },
    async onChangeStart({ collectFlag, id }) {
      loading.showLoading()
      await speechCraftCollect({
        speechCraftId: id,
        collectFlag: collectFlag == '0' || collectFlag === null ? true : false,
      })
      this.$toast(
        collectFlag == '0' || collectFlag === null
          ? this.$t('已收藏')
          : this.$t('取消收藏')
      )
    },
  },
}
