<!--
* @Author: tianzl
* @Date: 2023-11-13 11:00:14
* @LastEditors: tianzl
* @LastEditTime: 2023-11-13 11:00:14
* @Description: 试驾完成
 -->
 <template>
<div class="test-drive-complete" @click="toDriveDetails">
  <div>
    <span class="mr8 event-type">
      {{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}
    </span>
    <span class="event-type">{{ trip.followUserName }}</span>
  </div>
  <div>
    <span class="label mr8">{{$t('试驾场景')}}</span>
    <span class="value">{{ trip.extendInfo.testDriveType | codeFormat }}</span>
  </div>
  <!-- <div>
    <span class="label mr8">{{$t('是否预约')}}</span>
    <span class="value">{{ trip.extendInfo.appointmentFlag | getdectText(trip.extendInfo, 'appointmentFlag', $store,'2016') }}</span>
  </div> -->
  <div>
    <span class="label mr8">{{$t('试驾类型')}}</span>
    <span class="value">{{ trip.extendInfo.appointmentMethod | getdectText(trip.extendInfo, 'appointmentMethod', $store,'2025') }}</span>
  </div>
  <!-- 判断有无修改预约 -->
  <template v-if="trip.extendInfo.beforeTrip">
    <div style="display: flex;">
      <span class="label mr8" style="width: 60px;min-width:60px">{{$t('试驾车型（修改前）')}}</span>
      <span class="value" style="flex:1">
        {{ trip.extendInfo.beforeTrip.vehicleName }} {{ trip.extendInfo.beforeTrip.carNumber }}
      </span>
    </div>
    <div style="display: flex;">
      <span class="label mr8" style="width: 60px;min-width:60px">{{$t('试驾车型（修改后）')}}</span>
      <span class="value" style="flex:1">
        {{ trip.extendInfo.vehicleName }} {{ trip.extendInfo.carNumber }}
      </span>
    </div>
    <div style="display: flex;">
      <span class="label mr8" style="width: 60px;min-width:60px">{{$t('试驾时间（修改前）')}}</span>
      <span class="value" style="flex:1">
        {{ trip.extendInfo.beforeTrip.testDriveTime }}
      </span>
    </div>
    <div style="display: flex;">
      <span class="label mr8" style="width: 60px;min-width:60px">{{$t('试驾时间（修改后）')}}</span>
      <span class="value" style="flex:1">
        {{ trip.extendInfo.testDriveTime }}
      </span>
    </div>
  </template>
  <template v-else>
    <div>
      <span class="label mr8">{{$t('试驾车型')}}</span>
      <span class="value">{{ trip.extendInfo.vehicleName }}</span>
    </div>
    <div>
      <span class="label mr8">{{$t('试驾时间')}}</span>
      <span class="value">{{ trip.extendInfo.testDriveTime }}</span>
    </div> 
    <!-- 集中试驾需要展示活动名称 -->
    <div v-if="trip.extendInfo.testDriveType==='2002003'">
      <span class="label mr8">{{$t('试驾活动')}}</span>
      <span class="value">{{ trip.extendInfo.activityTitle }}</span>
    </div> 
  </template>
  <van-icon name="arrow" class="arrow"/>
</div>
</template>
<script>
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    toDriveDetails(){
      this.$router.push({
        name: 'appointmentTestDriveDetail',
        query:{
          id: this.trip.extendInfo.appointmentId,
          clueId: this.trip.leadId
        }
      })
    }
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
.test-drive-complete{
  position: relative;
  width: 100%;
  .arrow{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
</style>