/*
 * @Author: 张超越 
 * @Date: 2024-03-19 09:10:14 
 * @Last Modified by: 张超越
 * @Last Modified time: 2024-04-25 15:57:37
 */

<template>
  <div class="SelectCompeting">
    <div class="body">
      <div class="title-box">
        <div class="title">{{ title }}</div>
      </div>

      <CommonCascader :items="list" :defaultValue="defaultValue" :nameClickCheck="true" :tagsInput="false" @change="handleChange"></CommonCascader>
    </div>

    <div class="footer-box">
      <div class="button" @click="handleClose">{{ $t('返回') }}</div>
      <div class="button submit" @click="handleOK">{{ $t('确定') }}</div>
    </div>
  </div>
</template>

<script>
// APIs

// Tools
import loading from '@/utils/loading'

// Components
import CommonCascader from '@/components/v2/system/CommonCascader/index.vue'
import { isArray } from 'lodash'

export default {
  name: 'SelectCompeting',
  components: {
    CommonCascader
  },
  props: {
    details: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: '兴趣爱好'
    }
  },
  data() {
    return {
      tags: [],
      ids: [],
      defaultValue: [],
      checkedObjectArray: [],
      checkedArray: [],
      checkedTree: []
    }
  },
  computed: {
    getTags() {
      return this.tags || []
    },
    getSelectCodes() {
      return this.ids.map(i => i.valueCode)
    },
    getSelectObjArray() {
      // 暂时不用
      return this.checkedObjectArray.map(arr => {
        return arr.map((i, index) => {
          const { name, value, brandNameEn, modelNameEn } = i
          let nameEn = index === 0 ? brandNameEn : modelNameEn
          return { name, value, nameEn }
        })
      })
    },
    getCheckedTree() {
      return this.checkedTree.map(i => {
        console.info('getCheckedTree item', i)
        let { children: models, name, nameEn, value: code } = i
        if (models && isArray(models) && models.length) {
          models = models.map(j => {
            let { name, nameEn, value: code } = j
            return {
              code, name, nameEn
            }
          })
        }
        return {
          code, name, models, nameEn
        }
      })
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      let defaultValue = []
      this.details.forEach(item => {
        if (!item.models) {
          defaultValue.push([item.code])
          return
        }

        item.models.forEach(j => {
          defaultValue.push([item.code || item.code, j.code || j.value])
        })
      })

      this.defaultValue = defaultValue
      console.info('this.defaultValue', this.defaultValue)
    },
    handleClose() {
      this.$emit('close')
    },
    handleChange(values) {
      console.info('handle change', values)
      const { checkedTree = [], } = values
      this.checkedTree = checkedTree
    },
    async handleOK() {
      console.info('getCheckedTree', this.getCheckedTree)
      this.$emit('ok', this.getCheckedTree)
    }
  }
}
</script>

<style lang="less" scoped>
.SelectCompeting {
  background-color: #f6f6f6;
  min-height: 100vh;
  padding: 16px;

  .body {
    background-color: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05); 
    border-radius: 8px 8px 8px 8px;
    .title-box{
      display: flex;
      align-items: center;
      .title {
        height: 48px;
        line-height: 48px;
        margin: 0 12px;
        flex: 1;
        font-size: 16px;
        color: #0D171A;
        border-bottom: 1px solid rgba(13,23,26,0.05);
        // text-transform: capitalize;
      }
    }
  }
  
  .footer-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: #fff;

    .button {
      flex: 1;
      text-align: center;
      height: 42px;
      line-height: 42px;
      background: #FFFFFF;
      border-radius: 100px 100px 100px 100px;
      border: 1px solid #0D171A;
      margin-right: 8px;
      color: #0D171A !important;

      &.submit {
        background-color: #EED484;
        border-color: #EED484;
      }
    }
  }
}
</style>