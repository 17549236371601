<!--
* @Author: tianzl
* @Date: 2023-10-19 10:57:05
* @LastEditors: tianzl
* @LastEditTime: 2023-10-19 10:57:05
* @Description: 线索轨迹组件
 -->
<template>
<div :class="['trips-container', ...css]">
  <div class="labels">
    <div v-for="label in getLabels" :key="label.code" >
      <!-- 展示逻辑 -->
      <!-- 预约：重复留资为 0 则都不展示，试驾为 0 要展示（并且默认选中） 线索：试驾为 0 不展示 -->
      <div
        :class="['label',label.code == activeCode ? 'active' : '']"
        :style="getItemStyle(label)"
        @click="onTabClick(label)">
        {{ label.name }}
      </div>
    </div>
  </div>
  <div class="event-list" :class="{ expanded: open }">
    <div>
      <template v-for="trip in details.trips">
        <event-item v-if="!['1022068'].includes(trip.eventTypeCode)" :key="trip.id" :trip="trip"/>
      </template>
    </div>
  </div>
  <div class="arrow">
    <div @click="onOpen">
      <span>{{ open ? '收起' : '展开' }}</span>
      <van-icon :class="{ expanded: open }" class="down" name="arrow-down" size="16px" />
    </div>
  </div>
</div>
</template>
<script>
import EventItem from './component/event-item.vue'
import { debounce } from 'lodash'
let vm
export default {
  name: 'Trips',
  components: { EventItem },
  filters:{
    getdectText(val,store,dictCode){
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === val.code)
      return theOne?.label || ''
    }
  },
  props:{
    details:{
      type: Object,
      default:()=>{}
    },
    active:{
      type: String,
      default:''
    },
    css: {
      type: Array,
      default: () => []
    },
    clueInfo: {
      type: Object,
      default:()=>{}
    }
  },
  data(){
    return {
      activeCode: '',
      open: true 
    }
  },
  computed: {
    getLabels() {
      return this.details?.labels?.filter(i => i.name)
    }
  },
  watch: {
    'clueInfo': {
      handler(value) {
        if (!value) return false
        value.state && (this.open = value.state != 2000045) // 战败默认收起
      },
      immediate: true
    }
  },
  mounted(){
    vm = this
    if (this.active) this.activeCode = this.active
  },
  methods: {
    onOpen: debounce(()=>vm.open = !vm.open),
    getItemStyle(label) {
      const { color = '#0040C5,#e6ecfa' } = label
      const [c, background] = color.split(',')

      let obj = {
        color: c,
        background,
        border: `1px solid ${background}`
      }

      if (this.activeCode == label.code) {
        obj.border = `1px solid ${c}`
      }
      return obj
    },
    onTabClick(label) {
      console.info('onTabClick label', label)
      if (!label.isClick) return false

      const { code,count } = label
      if (code===this.activeCode) this.activeCode = ''
      else this.activeCode = code
      this.$emit('change',this.activeCode)
    }
  }
}
</script>
<style lang="less" scoped>
.event-list {
  display: grid;
  overflow: hidden;
  grid-template-rows: 0fr;
  transition: grid-template-rows .3s ease-in-out;
  will-change: grid-template-rows;
  >div {
    min-height: 0;
  }
  &.expanded {
    grid-template-rows: 1fr;
  }
}
.arrow {
  display: flex;
  justify-content: center;
  >div {
    padding: 4px 0;
    .down {
      margin-left: 2px;
      transition: transform 0.3s;
      will-change: transform;
      &.expanded {
        transform: rotate(-180deg);
      }
    }
  }
}
.trips-container{
  background: #fff;
  padding: 16px 12px;

  &.without-padding {
    padding: 16px 0;
  }
}

.labels {
  display: flex;
  flex-wrap: wrap;
}

.label{
  padding: 0 5px;
  height: 20px;
  line-height: 20px;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  border-radius: 2px 2px 2px 2px;
  background-color: #e6ecfa;
  color: #0040C5;
  margin-bottom: 8px;
  margin-right: 8px;
}
.active{
  border-color: #EED484;
  background-color: #EED484;
  color: #B9921A;
}
</style>
