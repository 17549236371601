<template>
	<div class="tag-wrap">
		<van-tag
			style="margin-left: 5px;border: 1px solid rgba(255,125,0,0.1);"
			color="rgba(255,125,0,0.1)"
			text-color="#FF7D00"
		>
			{{ typeData.config[item.classifyOne] }}</van-tag
		>
		<template v-if="item.sysLabelName">
			<van-tag
				v-for="(tag, index) in [item.sysLabelName]"
				:key="index"
				style="margin-left: 5px;border: 1px solid rgba(0,64,197,0.1);"
				color="rgba(0,64,197,0.1)"
				text-color="#0040C5"
				>{{ tag }}
			</van-tag>
		</template>
		<van-tag
			v-if="item.personLabel"
			style="margin-left: 5px;border: 1px solid rgba(0,64,197,0.1);"
			color="rgba(0,64,197,0.1)"
			text-color="#0040C5"
			>{{ item.personLabel }}
		</van-tag>
	</div>
</template>
<script>
export default {
  props: ['item', 'tagConfig', 'typeData'],
}
</script>
<style lang="less" scoped></style>
