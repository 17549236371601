<!--
* @Author: tianzl
* @Date: 2023-10-19 10:58:05
* @LastEditors: tianzl
* @LastEditTime: 2023-10-19 10:58:05
* @Description: 新建线索事件
 -->
<template>
<div :class="['event-item', trip.eventTypeCode === '1022075' ? 'event-item-special' : '']">
  <div v-if="trip.eventTypeCode !== '1022075'" class="follow-time">
    <div class="date">{{trip.followTime | getDate }}</div>
    <div class="right">
      <div class="month">
        <span v-if="isNotSameYear">{{trip.followTime | getYear }}</span>
        <span v-if="isNotSameYear">/</span>
        <span>{{trip.followTime | getMonth }}</span>
        <span v-if="!isNotSameYear">{{$t('月')}}</span>
      </div>
      <div class="time">{{trip.followTime | getTime }}</div>
    </div>
  </div>
  <div v-else class="follow-time"></div>
  <div :class="['info', trip.eventTypeCode==='1022075' ? 'info_state' : '']">
    <span v-if="trip.eventTypeCode === '1022004'">
      <!-- 线索跟进 -->
      <FollowClue :trip="trip"/>
    </span>
    <!-- 重新分配 -->
    <span v-else-if="['1022005','1022053'].includes(trip.eventTypeCode)">
      <AssignClue :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode === '1022006'">
      <!-- 跟进-无效 -->
      <follow-no-result :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode === '1022086'">
      <!-- 跟进-无效回访 -->
      <FollowInvalid :trip="trip"/>
    </span>
    <span v-else-if="['1022008','1022040'].includes(trip.eventTypeCode) ">
      <!-- 申请战败 -->
      <RequestFail :trip="trip"/>
    </span>
    <span v-else-if="['1022009', '1022051'].includes(trip.eventTypeCode)">
      <!-- 战败审核 -->
      <FailedClue :trip="trip"/>
    </span>
    <span v-else-if="['1022010','1022014'].includes(trip.eventTypeCode)">
      <!-- 取消预约 -->
      <CancelAppointment :trip="trip"/>
    </span>
    <span v-else-if="['1022011','1022013','1022031'].includes(trip.eventTypeCode)">
      <!-- 修改预约 -->
      <EditAppointment :trip="trip"/>
    </span>
    <span v-else-if="['1022007','1022015'].includes(trip.eventTypeCode)">
      <!-- 客户预约 -->
      <Appointment :trip="trip"/>
    </span>
    <span v-else-if="['1022020','1022021'].includes(trip.eventTypeCode)">
      <!-- 领取公共线索 -->
      <ReceivePublicLeads :trip="trip"/>
    </span>
    <span v-else-if="['1022023', '1022050'].includes(trip.eventTypeCode)">
      <!-- 无效审核 -->
      <InvalidClue :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode==='1022012'">
      <!-- 试驾完成 -->
      <TestDriveComplete :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode==='1022024'">
      <!-- 店内接待 -->
      <ShopReception :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode?.startsWith('1023')">
      <!-- 线索授权 -->
      <ClueAuth :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode==='1022027'">
      <!-- 新建订单 -->
      <CreateOrder :trip="trip"/>
    </span>
    <span v-else-if="['1022028','1022047'].includes(trip.eventTypeCode)">
      <!-- 修改订单 -->
      <EditOrder :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode==='1022029'">
      <!-- 订单状态变化 -->
      <OrderChange :trip="trip"/>
    </span>
    <span v-else-if="['1022032'].includes(trip.eventTypeCode)">
      <!-- 上门试驾审核 -->
      <DriveReview :trip="trip"/>
    </span>
    <span v-else-if="['1022034','1022045', '1022067'].includes(trip.eventTypeCode)">
      <!-- 订单跟进轨迹 -->
      <FollowForLockOrder :trip="trip"/>
    </span>
    <span v-else-if="['1022036','1022038'].includes(trip.eventTypeCode)">
      <!-- 线索培育 -->
      <ClueIncubation :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode==='1022037'">
      <!-- 线索培育，分配到店 -->
      <DistributionToShop :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode==='1022039'">
      <!-- 交车，交付跟进 -->
      <DeliverFollow :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode==='1022043'">
      <!-- 线索培育-客户预约 -->
      <IncubationAppointment :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode==='1022048'">
      <!-- 订单修改审核 -->
      <OrderReview :trip="trip"/>
    </span>
    <span v-else-if="trip.eventTypeCode==='1022075'">
      <!-- 话单的线索跟进 -->
      <CallRecord :trip="trip"></CallRecord>
    </span>
    <span v-else-if="trip.eventTypeCode === '1022076'">
      <LeaveJob :trip="trip"></LeaveJob>
    </span>
    <span v-else-if="trip.eventTypeCode === '1022080'">
      <!-- 线索合并 -->
       <MergeClue :trip="trip"></MergeClue>
    </span>
    <span v-else-if="['1022082', '1022083', '1022084', '1022085'].includes(trip.eventTypeCode)">
      <!-- 退单申请相关 -->
      <RefundOrder :trip="trip"></RefundOrder>
    </span>
    <!-- 门店改派 -->
    <ChangeDealer v-else-if="['1022077', '1022078'].includes(trip.eventTypeCode)" :trip="trip"></ChangeDealer>
    <UnBindWechat v-else-if="['1022081'].includes(trip.eventTypeCode)" :trip="trip"></UnBindWechat>
    <!-- 只有一种展示形态的节点 -->
    <!-- TODO 限制展示的 eventTypeCode -->
    <span v-else>
      <div>
        <span class="mr8 event-type">{{ trip.eventTypeCode | getdectText(trip,eventTypeObj,$store) }}</span>
        <span class="event-type">{{ trip.followUserName }}</span>
      </div>
      <div v-for="field in fieldList" :key="field.key">
        <template v-if="!field.isValue">
          <span class="label mr8">{{ field.label }}</span>
          <span class="value">{{ trip[field.key] | getdectText(trip,field,$store) }}</span>
        </template>
        <!-- 非必需的字段，有值才展示 -->
        <template v-if="field.isValue&&trip[field.key]">
          <span class="label mr8">{{ field.label }}</span>
          <span class="value">{{ trip[field.key] | getdectText(trip,field,$store) }}</span>
        </template>
      </div>
    </span>
  </div>
</div>
</template>
<script>
import AssignClue from './assign-clue.vue'
import FollowClue from './follow-clue.vue'
import FollowNoResult from './follow-no-result.vue'
import FollowInvalid from './follow-invalid.vue'
import CancelAppointment from './cancel-appointment.vue'
import EditAppointment from './edit-appointment.vue'
import FailedClue from './failed-clue.vue'
import Appointment from './appointment.vue'
import RequestFail from './request-fail.vue'
import ReceivePublicLeads from './receive-public-leads.vue'
import InvalidClue from './invalid-clue.vue'
import ShopReception from './shop-reception.vue'
import TestDriveComplete from './test-drive-complete.vue'
import ClueAuth from './clue-auth.vue'
import EditOrder from './edit-order.vue'
import CreateOrder from './create-order.vue'
import OrderChange from './order-change.vue'
import DriveReview from './drive-review.vue'
import FollowForLockOrder from './follow-for-lock-order.vue'
import ClueIncubation from './clue-incubation.vue'
import DistributionToShop from './distribution-to-shop.vue'
import DeliverFollow from './deliver-follow.vue'
import IncubationAppointment from './incubation-appointment.vue'
import OrderReview from './order-review.vue'
import CallRecord from './CallRecord.vue'
import LeaveJob from './leaveJob.vue'
import ChangeDealer from './ChangeDealer.vue'
import UnBindWechat from './UnBindWechat.vue'
import MergeClue from './MergeClue.vue'
import RefundOrder from './RefundOrder.vue'
import moment from 'moment'
import { i18n } from '@/main'
const eventFieldMap = {
  '1022001': [
    // 新建线索
    { key:'sourceType',label:i18n.t('线索归属'),dictCode: '1007' },
    { key:'channel',label:i18n.t('来源渠道') },
    { key:'sysRemark',label:i18n.t('意向金'), isValue: true },
    { key:'followRemark',label:i18n.t('备注'), isValue: true },
  ],
  '1022002':[
    // 重复留资
    { key:'sourceType',label:i18n.t('线索归属'),dictCode: '1007' },
    { key:'channel',label:i18n.t('来源渠道') },
    { key:'sysRemark',label:i18n.t('意向金'),isValue: true }
  ],
  '1022003':[
    // 分配线索
    { key:'sysRemark',label:i18n.t('线索所属变更') }
  ],
  '1022018': [
    // 线索跟进
    { key:'followRemark',label:i18n.t('跟进备注') }
  ],
  '1022017':[
    // 线索回收
    { key:'state',label:i18n.t('线索状态'),dictCode:2000 },
    { key:'responsibleUserName',label:i18n.t('原跟进人') },
    { key:'sysRemark',label:i18n.t('回收记录') },
  ],
  '1022019':[
    // 分配店内公共线索
    { key:'sysRemark',label:i18n.t('分配结果') },
  ],
  '1022022':[
    // 同城共享
    { key:'followRemark',label:i18n.t('共享原因') },
  ],
  '1022016':[
    // 领取新线索
    { key:'sysRemark',label:i18n.t('线索所属变更') },
    { key:'followRemark',label:i18n.t('备注') },
  ],
  '1022026':[
    // 线索转移
    { key:'sysRemark',label:i18n.t('线索所属变更') },
  ],
  '1022033':[
    // 客户下单，自动关闭跟进任务
    { key:'sysRemark',label:i18n.t('备注') }
  ],
  '1022030':[
    // 订单分配
    { key:'sysRemark',label:i18n.t('订单所属变更') }
  ],
  '1022035': [
    // 退意向金
    { key:'followRemark',label:i18n.t('退订原因') },
    { key:'sysRemark',label:i18n.t('退订金额') },
  ],
  '1022044': [
    { key:'sysRemark',label:i18n.t('备注') },
  ],
  // 心愿单
  '1022049':[
    { key:'followRemark',label:i18n.t('关注车型') },
    { key:'sysRemark',label:i18n.t('操作时间') },
  ],
  // 战败回访
  '1022054':[
    { key:'followResult',label:i18n.t('跟进结果'),dictCode:1011 },
    { key:'followRemark',label:i18n.t('跟进记录') },
  ],
  // 跨店转移
  '1022056':[
    { key:'sysRemark',label:i18n.t('目标门店') },
    { key:'followRemark',label:i18n.t('转移原因') },
  ],
  // 店长申请转入线索-审核
  '1022055':[
    { key:'sysRemark',label:i18n.t('审核结果') },
    { key:'followRemark',label:i18n.t('备注') },
  ],
  // 店长申请转入线索
  '1022057':[
    { key:'sysRemark',label:i18n.t('申请信息') },
  ],
  // 战败线索激活
  '1022059' :[
    { key:'sysRemark',label:i18n.t('备注') },
  ],
  // 跟进备注
  '1022064' :[
    { key:'followRemark',label:i18n.t('留言') },
  ],
  // 特殊资源备注
  '1022066': [
    { key:'sysRemark',label:i18n.t('内容') },
  ],
  // 战败回访
  '1022062': [
    { key:'followMode',label:i18n.t('跟进方式'),dictCode: '1010' },
    { key:'followResult',label:i18n.t('跟进结果'),dictCode: '1011' },
    { key:'followRemark',label:i18n.t('跟进记录') },
  ],
  // 无效回访
  '1022073': [
    { key:'followMode',label:i18n.t('跟进方式'),dictCode: '1010' },
    { key:'followResult',label:i18n.t('跟进结果'),dictCode: '1011' },
    { key:'followRemark',label:i18n.t('跟进记录') },
  ],
  // 已分配到店或已到店的线索跟进
  '1022063': [
    { key:'followMode',label:i18n.t('跟进方式'),dictCode: '1010' },
    { key:'followRemark',label:i18n.t('跟进记录') },
  ],
  // 客户提前到店
  '1022074': [
    { key: 'followRemark', label: i18n.t('备注') },
    { key: 'sysRemark', label: i18n.t('接待门店') },
  ],
  // 定金协议提交审核
  '1022069':[{ key: 'sysRemark', label: i18n.t('备注') }],
  // 购车合同提交审核
  '1022071':[{ key: 'sysRemark', label: i18n.t('备注') }],
  // 定金协议审核
  '1022070':[
    { key: 'sysRemark', label: i18n.t('审核结果') },
    { key: 'followRemark', label: i18n.t('驳回原因'), isValue: true }
  ],
  // 购车合同审核
  '1022072': [
    { key: 'sysRemark', label: i18n.t('审核结果') },
    { key: 'followRemark', label: i18n.t('驳回原因'), isValue: true }
  ],
  '1011038': [{ key: 'followRemark', label: i18n.t('跟进记录') }],
  // 退车
  '1022079': [{ key: 'followRemark', label: i18n.t('备注') }]
}
export default {
  components:{ 
    FollowNoResult,// 跟进无效
    FollowInvalid, // 无效回访
    CancelAppointment,// 取消预约
    FailedClue,// 战败审核
    AssignClue,// 重新分配
    EditAppointment,// 修改预约
    Appointment,// 预约
    FollowClue,// 跟进
    RequestFail, // 申请战败
    ReceivePublicLeads, // 领取公共线索
    InvalidClue, // 无效审核
    ShopReception, // 店内接待
    TestDriveComplete, // 试驾完成
    ClueAuth, // 线索授权
    EditOrder, // 修改订单
    CreateOrder, // 新建订单
    OrderChange, // 订单状态变化
    DriveReview, // 上门试驾审核
    FollowForLockOrder, // 锁单跟进
    ClueIncubation, // 线索培育
    DistributionToShop, // 线索培育分配到店
    DeliverFollow, // 交付跟进
    IncubationAppointment, // 线索培育-客户预约
    OrderReview, // 订单修改审核
    CallRecord, // 话单跟进
    LeaveJob, // 离职继承
    ChangeDealer, // 门店指派
    UnBindWechat,
    MergeClue, // 合并线索
    RefundOrder, // 退单申请
  },
  filters:{
    getDate(val){
      if (!val) return ''
      return val.split(' ')[0].split('-')[2]
    },
    getYear(val){
      if (!val) return ''
      return val.split(' ')[0].split('-')[0]
    },
    getMonth(val){
      if (!val) return ''
      return val.split(' ')[0].split('-')[1]
    },
    getTime(val){
      if (!val) return ''
      return val.split(' ')[1].substring(0,5)
    },
    getdectText(val,trip,field,store){
      // 不需要查字典
      if (!field.dictCode) return val
      // 没有值的情况
      if (!trip[field.key]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(field.dictCode)
      const theOne = options.find(item=>item.value === trip[field.key])
      return theOne?.label || ''
    }
  },
  // mixins:[myMixin],
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  },
  data(){
    return {
      eventTypeObj: {
        key: 'eventTypeCode',
        dictCode: '1022',
        label: ''
      }
    }
  },
  computed:{
    fieldList(){
      return eventFieldMap[this.trip.eventTypeCode]
    },
    isNotSameYear(){
      const year = moment(this.trip.followTime).format('YYYY')
      const theYear = new Date().getFullYear()
      return year != theYear
    }
  }
}
</script>
<style scoped lang="less" >
@import "../style/common.less";
</style>
