 
 <!-- '1022082': 退单申请, '1022083': 退单申请审核, '1022084': 退单申请撤销, '1022085': 退单申请取消 -->
 <template>
  <span>
    <div>
      <span class="mr8 event-type">
        {{ trip.eventTypeCode | getdectText(trip, 'eventTypeCode', $store,'1022') }}
      </span>
      <span class="event-type">{{ trip.followUserName }}</span>
    </div>
    <div>
      <span class="label mr8">{{ $t('订单号') }}</span>
      <span class="value">{{ trip.extendInfo?.appOrderId }}</span>
    </div>
    <template v-if="trip.eventTypeCode === '1022082'">
      <div>
        <span class="label mr8">{{ $t('订单状态') }}</span>
        <span class="value">{{ trip.extendInfo?.orderStatus | codeFormat }}</span>
      </div>
      <div>
        <span class="label mr8">{{ $t('销售门店') }}</span>
        <span class="value">{{ trip.extendInfo?.salesDealerName }}</span>
      </div>
      <div>
        <span class="label mr8">{{ $t('交付门店') }}</span>
        <span class="value">{{ trip.extendInfo?.deliveryDealerName }}</span>
      </div>
    </template>
    <template v-if="trip.eventTypeCode === '1022083'">
      <div>
        <span class="label mr8">{{ $t('审核岗位') }}</span>
        <span class="value">{{ trip.extendInfo?.auditRoleName }}</span>
      </div>
      <div>
        <span class="label mr8">{{ $t('审核结果') }}</span>
        <span class="value">{{ trip.extendInfo?.auditResult === 1 ? $t('通过') : $t('驳回') }}</span>
      </div>
    </template>
    <div>
      <span v-if="trip.eventTypeCode === '1022082'" class="label mr8">{{ $t('申请原因') }}</span>
      <span v-else-if="trip.eventTypeCode === '1022083'" class="label mr8">{{ $t('审核备注') }}</span>
      <span v-else-if="trip.eventTypeCode === '1022084'" class="label mr8">{{ $t('撤销原因') }}</span>
      <span v-else-if="trip.eventTypeCode === '1022085'" class="label mr8">{{ $t('取消原因') }}</span>
      <span class="value">{{ trip.extendInfo?.remark }}</span>
    </div>
  </span>
  </template>
<script>
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
    // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value === trip[field])
      return theOne?.label || ''
    }
  },
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
  <style scoped lang="less" >
  @import "../style/common.less";
  </style>